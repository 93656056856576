import React, { useEffect } from 'react'
import env from 'react-dotenv'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import BannerBgImg1 from "./school-profile-Bg/SchoolCover.jpg"
import BannerBgImg2 from "./school-profile-Bg/highschool.jpg"
import Rctangle1 from "./school-profile-Bg/SchoolLogo.png"
import Rctangle2 from "./school-profile-Bg/rectangle_4.png"
import Rctangle3 from "./school-profile-Bg/rectangle_5.png"
import Rctangle4 from "./school-profile-Bg/rectangle_6.png"
import Rctangle5 from "./school-profile-Bg/rectangle_7.png"
import Rctangle6 from "./school-profile-Bg/rectangle_8.png"
import { CircularProgress } from "@mui/material";
import { Button } from "@mui/material";
import { post_list_api } from '../../../services/user_apis';
import { division_list } from '../../../services/user_apis';
import { school_detail, postListCategoryWise, cheer_me_up, create_chat_room, teacher_list, get_general_notifications } from '../../../services/user_apis';
import { useNavigate, Link } from 'react-router-dom';
import PostListing from "../home/PostListing";
import { todayHomework } from "../../common";
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import TextToSpeech from '../../../services/textToSpeech'
import Funzone from "../../inc/funzone";


const School_Profile = ({ socketData, homeworknotice }) => {
    const { t } = useTranslation();
    let user = JSON.parse(localStorage.getItem('user'));
    const [ datalimit, setDatalimit ] = React.useState({ limit: 10, page: 1 });
    const [circular, setcircular] = React.useState(true)
    const [post_list_school, setPost_list_school] = React.useState([])
    const [classlist, setClassList] = React.useState([])
    const [school_data, setschooldata] = React.useState(false)
    let navigate = useNavigate();
    let [cheermeup, setCheermeup] = React.useState([]);
    const [reportPostData, setReportPostData] = React.useState("")
    let [reportType, setReportType] = React.useState("");
    const [teacherlist, setTeacherlist] = React.useState([]);
    let [uploadImagetype, steUploadImageType] = React.useState("cover");

    const getschooldetail = async () => {
        // console.log("get school detailll")
        // const user = JSON.parse(localStorage.getItem('user'))
        // const school_data = await school_detail(user.school_id)
        // setschooldata(school_data.data)
    }

    useEffect(() => {
        school_detail_api()
    }, [])

    useEffect(() => {

        const post_list_school = async () => {
            const user = JSON.parse(localStorage.getItem('user'))
            var body = {
                ...{ "post_category": "school", "school_id": user.school_id },
                ...datalimit
            };
            setcircular(true)
            const response = await postListCategoryWise(body)
            if (response.code === 1) {
                setcircular(false)
                setPost_list_school(response.data);
                // setcircular(false)
            }
            setcircular(false);
        }
        post_list_school()
    }, [datalimit])

    useEffect(() => {

        const class_list1 = async () => {

            const user = JSON.parse(localStorage.getItem('user'))
            const school_id = user.school_id
            const response = await division_list({ "school_id": school_id })
            if (response.code === 1) {
                // console.log("Devision List", response.data);
                setClassList(response.data);
            }
            teacherAlllist();
        }
        class_list1()
    }, [])

    const updateLimit = () => {
        setcircular(true)
        setDatalimit({...datalimit, "limit": Number(datalimit.limit) + 10 })
    }

    const school_detail_api = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user'))
            const school_data = await school_detail(user.school_id)
            setschooldata(school_data.data);
        }
        catch (e) {
            navigate('/')
        }
    }

    const pagignation = () => {
        if (circular) {
            return <CircularProgress />
        }
        if (!circular) {
            if (post_list_school.length < datalimit.limit) {
                return <div className="no-more-activities"><TextToSpeech text={t('No more activities found.')} /></div>
            }
            return <Button variant="outlined" size="medium" onClick={updateLimit}><TextToSpeech text={t('See More')} /></Button>
        }
    }

    const getPassDataOtherUser = (data) => {
        var dataPass = { "user_id": data.teacher_id, "username": data.username, "display_user_name": data.display_user_name, "user_about_me": data.about_me, "avatar_full_path": data.avatar }
        return dataPass
    }

    const createChatRoom = async (receiver_user) => {
        let post_body = {
            sender_id: user.user_id,
            sender_type: user.user_role,
            receiver_type: "teacher",
            school_id: user.school_id,
            receiver_id: receiver_user.teacher_id,
        }
        const response = await create_chat_room(post_body)
        if (response.code === 1) {
            setTimeout(() => {
                // console.log("socket execute")
                socketData({ user_id: receiver_user.teacher_id, user_role: 'teacher' })
            }, 1000);
            $(".chat-with-friend").addClass("chat-with-desk-friend-active");
        }
    }

    const teacherAlllist = async () => {
        let post_body = {
            school_id: user.school_id,
        }
        const response = await teacher_list(post_body)
        if (response.code === 1) {
            setTeacherlist(response.data)
        } else {
            setTeacherlist([])
        }

    }

    return (
        <React.Fragment>

            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion text-left">
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8 pr-md-0">
                                <div className="main-content">
                                    <div className='my-profile school-profile'>
                                        {user.user_role === 'teacher' ?
                                        <>
                                        <div className="p-banner" style={{
                                            backgroundImage: `url('${(school_data && school_data.school_cover_image_full_path || BannerBgImg1)}')`
                                        }}>
                                            <span className="cover-photo-change" data-target="#change_schoolCoverImage" data-toggle="modal" onClick={() => steUploadImageType("cover")}><TextToSpeech text={t('Choose different Cover Photo')} /></span>
                                        </div>
                                        <div className="p-icon">
                                            <div className="p-main-pic">
                                                <img src={(school_data && school_data.school_logo_full_path || `${env.PRO_IMG}school-img.png`)} alt={t("School logo")}/>
                                                <span role="button" className="cover-photo-change logo-image" data-target="#change_schoolAvatarImage" data-toggle="modal" onClick={() => steUploadImageType("logo")} aria-label={t('Choose Logo')}><TextToSpeech text={t('Choose Logo')} /></span>
                                            </div>

                                        </div>
                                        </>
                                        :
                                        <>
                                        <div className="p-banner" style={{
                                            backgroundImage: `url(${(school_data && school_data.school_cover_image_full_path || BannerBgImg1)})`
                                        }}>
                                        </div>
                                        <div className="p-icon">
                                            <div className="p-main-pic">
                                                <img src={(school_data && school_data.school_logo_full_path || BannerBgImg1)} alt={t("School logo")} />
                                            </div>

                                        </div>
                                        </>}
                                        <div className="p-name pname-location">
                                            <p><TextToSpeech text={school_data ? school_data.school_name: ''} /></p>
                                            <h6 className='m-1'><img src="assets/web/location-1.png" alt={t("Location icon")} /> <TextToSpeech text={school_data ? school_data.school_location : null} /></h6>
                                        </div>
                                        <div className="post-content">
                                            <ul className="nav nav-tabs" role="tablist">
                                                <li role="presentation"><a href="#posts" data-toggle="tab" className="active" title={t('Posts')}><TextToSpeech text={t('Posts')} /></a></li>
                                                <li role="presentation" onClick={getschooldetail}><a href="#aboutmaxy" title={t('About School')} data-toggle="tab" > <TextToSpeech text={t('About School')} /></a></li>
                                                <li role="presentation"><a href="#friends" data-toggle="tab" title={t('Class Names')} className="border-0"> <TextToSpeech text={t('Class Names')} /></a></li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="posts">
                                            {
                                                <PostListing data={post_list_school}
                                                    callBackData={(data) => {
                                                        setReportType(data.type)
                                                        setReportPostData(data.reportdata)
                                                    }} />
                                            }
                                            <br/>
                                                <center>{pagignation()}</center>
                                            <br/>
                                            <br/>
                                        </div>
                                        <div className="tab-pane" id="aboutmaxy">
                                            {
                                                school_data && <div className="about-profile-info">
                                                    <ul role="tablist">
                                                        <li role="presentation">
                                                        <TextToSpeech text={`<p>${t('Number of Classes')}: <b>${school_data.school_total_division}</b></p>`} />
                                                            {/* <span>12</span> */}
                                                        </li>
                                                        <li role="presentation">
                                                            <TextToSpeech text={`<p>${t('Location')}: <b>  ${school_data.school_location}</b></p>`} />
                                                            {/* <span>Sri Aurobindo Marg, New Delhi</span> */}
                                                        </li>
                                                        <li role="presentation">
                                                            <TextToSpeech text={`<p>${t('Students')}: ${school_data.student_count}</p>`} />
                                                        </li>
                                                        <li role="presentation">
                                                            <TextToSpeech text={`<p>${t('Created on')}: <b>${school_data.created_at}</b></p>`} />
                                                        </li>
                                                        <li role="presentation">
                                                            <TextToSpeech text={`<p>${t('School Email')}: <b> ${school_data.school_email}</b></p>`} />
                                                        </li>
                                                        <li role="presentation">
                                                            <TextToSpeech text={`<p>${t('School Webstie URL')}: <b> ${school_data.school_website_url}</b></p>`} />
                                                        </li>
                                                    </ul>
                                                </div>
                                            }

                                        </div>
                                        <div className="tab-pane" id="friends">
                                            <div className="className-list">
                                                <br></br>
                                                <div className="row" data-message={t("School's class list")} role="tablist">
                                                    {
                                                        classlist && classlist.map((value, i) => (
                                                            <div className="col-lg-6 col-md-6" key={i}  role="presentation">
                                                                <div className="class-box">
                                                                    <div className="class-img">
                                                                        <img src="assets/web/class-list.svg" alt={t("Class List")} />
                                                                    </div>
                                                                    <div className="class-details">
                                                                        <h3><TextToSpeech text={value.class_name + " - " + value.division_name} /></h3>
                                                                        <div>
                                                                            <b><img src="assets/web/team.png" alt={t("Team icon")}/> <TextToSpeech text={`${" " + value.student_count} ${t('Students')}`} /></b>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        ))
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="right-side">
                                    {user.user_role === 'teacher' &&
                                        <div className="member-right">
                                        <h3><TextToSpeech text={`${t('Teachers in your School')} (${teacherlist.length})`} /></h3>
                                            <div className="mem-list">
                                                <ul role="tablist">
                                                    {teacherlist && teacherlist.map((value, i) => (
                                                        <li key={i} role="presentation">
                                                            <div className='myclass-member-view add-friend-section'>
                                                                <img src={value?.avatar != null ? `${value?.avatar}` : `${env.PRO_IMG}pro-img.png`} alt={t("Profile Avatar")} />

                                                                <Link to="/otherUserProfile" state={{ "otherUserData": getPassDataOtherUser(value) }} title={t("User profile link")}>
                                                                    <p><TextToSpeech text={`${value?.display_user_name != "" ? value?.display_user_name : value?.username}`} /></p>
                                                                </Link>
                                                                {value.teacher_id != user.user_id &&
                                                                    <button onClick={() => createChatRoom(value)} className="add-friend" aria-label={t("Chat with this teacher")}>
                                                                        <TextToSpeech text={t('Chat')} />
                                                                    </button>}
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                        </div>
                                    }
                                    {todayHomework(homeworknotice)}
                                    {user.user_role === "student" && <Funzone />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <Modal report_type={reportType} report_data={reportPostData} isUpdate={(data) => (data === true && setReportPostData(""), school_detail_api())} schoolDetails={school_data} uploadImagetype={uploadImagetype} />
        </React.Fragment>
    )
}

export default School_Profile
