import React from 'react';
import { Link } from 'react-router-dom';
import env from "react-dotenv";
import { useTranslation } from 'react-i18next';
import TextToSpeech from '../../services/textToSpeech';

export const todayHomework = (homeworknotice) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation();
    
    if (homeworknotice.length > 0) {
        return (<div className="notice-box">
            <h3><img alt={t("Homework Last Day")} src={`${process.env.PUBLIC_URL + "/" + env.HOMEWORK_PNG}star.png`} /> <TextToSpeech text={t('Homework Last Day')} /></h3>
            <div className="article">
                <ul role="tablist">
                {
                    (homeworknotice.length > 0) && homeworknotice.map((note, i) => {
                        return <li role="presentation" key={i} aria-label={note.homework_title}><Link className="homework-widget-list" to={"/homeworkDetails"} state={{ homworkData: note }} title={note.homework_title}><TextToSpeech text={note.homework_title} /></Link></li>
                    })
                }
                </ul>
            </div>
        </div>)
    }
}
