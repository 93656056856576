import React, { useEffect, useState } from "react";
import { timeConvert } from "../../../Utils/TimeUtils";
import env from "react-dotenv";
import { useTranslation } from 'react-i18next';
import { delete_comment, like_dislike } from "../../../services/user_apis";
import Swal from "sweetalert2";
import TextToSpeech from "../../../services/textToSpeech";

export default function CommentListing(props) {
    const { t } = useTranslation();
    const [arrData, setData] = useState(props?.data);
    let user = JSON.parse(localStorage.getItem("user"));
    const likeApiCallForComment = (data, position) => {
        const post_body = {
            like_dislike_type: t("comment"),
            like_dislike_type_id: data.comment_id,
        };
        const response = like_dislike(post_body).then((response) => {
            if (response.code === 1) {
                props.data[position].is_liked = response.data.is_liked;
                if (response.data.is_liked === 1) {
                    document.getElementById("imgLike" + data.comment_id).src =
                        process.env.PUBLIC_URL +
                        "/" +
                        env.LIKE_PNG +
                        "dislike.png";
                } else {
                    document.getElementById("imgLike" + data.comment_id).src =
                        process.env.PUBLIC_URL +
                        "/" +
                        env.LIKE_PNG +
                        "like.png";
                }
            }
        });
    };

    const renderLike = (value, index) => {
        // console.log(value);
        var imgSrc = "";

        if (value.is_liked === 1) {
            imgSrc = `${
                process.env.PUBLIC_URL + "/" + env.LIKE_PNG
            }dislike.png`;
        } else {
            imgSrc = `${process.env.PUBLIC_URL + "/" + env.LIKE_PNG}like.png`;
        }

        return (
            <>
                {
                    <a title={t("Like Dislikes")}
                        onClick={() => likeApiCallForComment(value, index)}
                        style={{ display: "flex", "alignItems": "center" }}
                    >
                        {" "}
                        <img
                            alt={t("Like Dislikes")}
                            id={"imgLike" + value.comment_id}
                            src={imgSrc}
                        />{" "}
                        <p
                            id={"aLike" + value.post_id}
                            style={{ "marginBottom": "0" }}
                        >
                             <TextToSpeech text={t("Like")} />
                        </p>{" "}
                    </a>
                }
            </>
        );
    };

    const deleteComment = async (data) => {
        Swal.fire({
            title: t("Are you sure?"),
            text: t("You won't be able to revert this!"),
            icon: t("warning"),
            showCancelButton: true,
            cancelButtonText: t("Cancel"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it!"),
        }).then((result) => {
            if (result.isConfirmed) {
                const post_body = {
                    comment_id: data?.comment_id,
                };
                const response = delete_comment(post_body).then((response) => {
                    if (response.code === 1) {
                        props?.handleData(data);
                    }
                });
            }
        });
    };

    return (
        <div className="w-100" role="tablist">
            {props.data &&
                props.data.map((value, index) => {
                    return (
                        <>
                            <div className="posted-coment mt-1" key={index} role="presentation">
                                <div className="p-left">
                                    <div className="posted-profile">
                                        <img alt={t("Avatar Image")} src={value.avatar_full_path} />
                                    </div>
                                    <div className="posted-details text-left" role="tabpanel">
                                        <h5><TextToSpeech text={value.display_user_name} /></h5>
                                        <p><TextToSpeech text={value.comment} /></p>
                                    </div>
                                </div>
                                <div className="p-right">
                                    <span>{timeConvert(value.created_at)}</span>
                                    <div className="p-flow">
                                        <div className="social-icon">
                                            {renderLike(value, index)}
                                        </div>

                                        {user.user_id != value.from_user_id && (
                                            <div className="social-icon">
                                                <a
                                                    title={t("comment")}
                                                    href="javaScript:"
                                                    data-target="#ReportMessage_Modal"
                                                    data-toggle="modal"
                                                    onClick={() =>
                                                        props.callBackData({
                                                            reportdata: value,
                                                            type: t("comment"),
                                                        })
                                                    }
                                                >
                                                    {" "}
                                                    <img
                                                        alt={t("Report Message")}
                                                        src={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            "/" +
                                                            "assets/images/home/course/report.svg"
                                                        }
                                                    />
                                                    <TextToSpeech text={t("Report")} />{" "}
                                                </a>
                                            </div>
                                        )}
                                        {user.user_id === value.from_user_id && (
                                            <div className="social-icon">
                                                <a
                                                    title={t("Delete Comment")}
                                                    onClick={() =>
                                                        deleteComment(value)
                                                    }
                                                >
                                                    <img
                                                        alt={t("Delete Comment")}
                                                        width={20}
                                                        height={15}
                                                        src={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            "/" +
                                                            "assets/images/home/course/delete.png"
                                                        }
                                                    />
                                                    <TextToSpeech text={t("Delete")} />
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                })}
        </div>
    );
}
