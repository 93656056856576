import React, { useState } from 'react'
import env from 'react-dotenv';
import { useTranslation } from 'react-i18next';
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import '../my-homework-teacher/AddHomeWork.css'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { uploadImage } from "../../../services/imageUpload";
import { add_announcement, add_lecture, update_announcement, update_lecture } from '../../../services/user_apis'
import TextToSpeech from '../../../services/textToSpeech';

export default function AddHomeWork({ socketData }) {
   const { t } = useTranslation();
   let location = useLocation()

   let { id } = useParams()
   // console.log("id parama s ===> ", id)
   var idList = id.split("&");

   let navigate = useNavigate();

   const type = idList[0]
   const classId = idList[1]
   const divisionId = idList[2]

   let user = JSON.parse(localStorage.getItem('user'));

   const [upload, setUpload] = React.useState([]);
   const [editImage, setEditImage] = React.useState();

   window.Buffer = window.Buffer || require("buffer").Buffer;

   const changeHandler = (event) => {
      let tmpFile = event.target.files[0]


      uploadImage(tmpFile, (callbackData) => {
         var fileType = "word"
         if (tmpFile.type.includes("image")) {
            fileType = "image"
         } else if (tmpFile.type.includes("pdf")) {
            fileType = "pdf"
         } else {
            fileType = "word"
         }
         // console.log("File Type", fileType);

         var tempData = { "media": callbackData, "file_type": fileType }
         setUpload(tempData)
         // console.log("uploaded data ===>", upload)
      })
   }

   const removeFile = () => {
      setUpload()
   }

   React.useEffect(() => {
      if (location.state != null) {
         document.getElementById("anTitle").value = location.state.announcData.title
         document.getElementById("anDescriptin").value = location.state.announcData.description
         setEditImage(location.state.announcData.announcement_image_full_path)
      }
   }, [])

   const clickSubmitButton = async (e) => {
      e.preventDefault();

      if (location?.state?.isEdit) {
         const post_body = {
            announcement_id: location.state.announcData.announcement_id,
            title: document.getElementById("anTitle").value,
            description: document.getElementById("anDescriptin").value,
            image: upload.media
         }
         if (upload === "") {
            delete post_body.image
         }
         const response = await update_announcement(post_body)
         if (response.code === 1) {
            navigate(-1)
         }
      } else {

         const post_body = {
            class_id: classId,
            division_id: divisionId,
            school_id: user?.school_id,
            title: document.getElementById("anTitle").value,
            description: document.getElementById("anDescriptin").value,
            image: upload.media
         }
         const response = await add_announcement(post_body)
         if (response.code === 1) {
            navigate(-1)
         }
      }
   }

   return (
      <React.Fragment>
         <div className="content">
            <div className="left-portion">
               <Left_Panel />
            </div>
            <div className="middle-portion">
               <Home_Header socketData={socketData} />
               <div className="container-fluid">
                  <section className="post-section pt-4">
                     <div className="container">
                        <div className="row justify-content-center">
                           <div className="col-lg-10">
                              <div className="my-school">
                                 <div className="create-post" data-message="Create announcement">
                                    <div className="post-title text-center">
                                       {location.state != null && location.state.isEdit === true ? <h3><TextToSpeech text={t("Edit Announcement")} /></h3> : <h3><TextToSpeech text={t("Create Announcement")} /></h3>}
                                    </div>
                                    <form id="form_validation" method="" data-message="Create announcement form" onSubmit={clickSubmitButton} action="#">
                                       <div className="class-form">
                                          <div className='form-group text-left'>
                                             {location.state != null && location.state.isEdit === true && <img alt={t("Edit Image")} className='w-50' height={'200px'} style={{ "object-fit": "cover" }} src={editImage} />}
                                          </div>
                                          <div className="form-group">
                                             <label for={t("Announcement Title")} className="hidden">{t("Announcement Title")}</label>
                                             <input type="text" name="anTitle" id="anTitle" placeholder={t("Announcement Title")} className="form-control" required />
                                          </div>
                                          <div className="form-group">
                                             <label for={t("Write Description")} className="hidden">{t("Write Description")}</label>
                                             <textarea className="form-control" name='anDescriptin' id='anDescriptin' placeholder={t("Write Description")} rows="5" required></textarea>
                                          </div>
                                          <div className="form-group">
                                             <div className="upload-view-box">
                                                <label className="upload-image">
                                                   <img alt={t("Upload Image")} src={process.env.PUBLIC_URL + "/assets/images/add.png"} className="addimg" />
                                                   <h5><img src={process.env.PUBLIC_URL + "/assets/images/u-img.png"} className="up" /> <TextToSpeech text={t("Upload Image")} /></h5>
                                                   <label for={t("Choose File")} className="hidden">{t("Choose File")}</label>
                                                   <input type="file" name="" onChange={changeHandler} accept="image/*," />
                                                </label>
                                                <div className="upload-view">
                                                   {(upload != "" && upload != undefined) &&
                                                      <div className='m-1'><img alt={("Uploaded file")} src={process.env.PUBLIC_URL + "/assets/images/file.png"} style={{ width: 35, height: 35 }} /> {upload.media} <i className="far fa-times" role="button" data-message="Remove file" onClick={() => removeFile()} ></i></div>
                                                   }
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group text-center">
                                             <label for={t("Edit Announcement")} className="hidden">{type === "e" ? t("Edit Announcement") : t("Create Announcement")}</label>
                                             <button className="cybersmart-btn-m btn-block" data-dismiss="modal" type='submit'><TextToSpeech text={type === "e" ? t("Edit Announcement") : t("Create Announcement")} /></button>
                                          </div>
                                       </div>
                                    </form>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
               </div>

            </div>
            <div className="content">
               <div className="middle-portion">
               </div>
            </div>

         </div>
         <Modal />
      </React.Fragment>
   )
}
