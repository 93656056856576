import React, { useEffect, useState } from 'react'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
//import { fun_zone_list } from '../../../services/user_apis'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import TextToSpeech from '../../../services/textToSpeech'

const Tutorial = ({ socketData }) => {
    const { t } = useTranslation();
    const { state } = useLocation();
    let url = state.tutorialdata.content;
    let description = state.tutorialdata.description;
    const [size, setSize] = useState({ width: `100%`, height: `${(parseInt(window.innerHeight, 10) - 200)}px` });

    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // }, [])

    useEffect(() => {
        if (window.innerHeight && window.innerWidth) {
            let innerHeight = (parseInt(window.innerHeight, 10) - 160);
            innerHeight = `${(innerHeight < 600 ? innerHeight : 600)}px`;
            setSize({ width: `100%`, height: innerHeight });
            const iframe = document.getElementById('musiczone');
            if (iframe) {
                iframe.style.minHeight = '50%';
                iframe.style.minWidth = '100%';
                iframe.style.maxHeight = '100%';
            } else {
                setSize({ width: `100%`, height: `100%` });
            }
        } else {
            setSize({ width: `100%`, height: `100%` });
        }
        
    }, [])

    return (
        <React.Fragment>
            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid">
                        <div data-message={t("Tutorial")}>
                            <TextToSpeech className="row align-item-center justify-content-center" text={url} />
                        </div>
                        {description ? <p>{description}</p> : <></>}
                    </div>
                </div>
            </div>
            <Modal />
        </React.Fragment>
    )
}

export default Tutorial


