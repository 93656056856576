import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
    name: 'language',
    initialState: { value: localStorage.getItem("language") || null },
    reducers: {
        addLanguage(state, action) {
            state.value = action.payload;
        },
        updateLanguage(state, action) {
            state.value = action.payload;
        },
        removeLanguage(state, action) {
            state.value = action.payload;
        },
    },
});

export const { addLanguage, updateLanguage, removeLanguage } = languageSlice.actions;
export default languageSlice.reducer;
