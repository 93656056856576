import React, { useEffect } from "react";
import { get_general_notifications } from "../../../services/user_apis";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TextToSpeech from "../../../services/textToSpeech";

const Funzone = () => {
    const { t } = useTranslation();
    const [generalNotificationData, setGeneralNotificationData] = React.useState([]);
    const user = JSON.parse(localStorage.getItem('user'));
    
    const generalNotificationList = async () => {
        let post_body = {
            user_role: user.user_role,
            classes: user.classes
        }
        const response = await get_general_notifications(post_body)
        if (response.code === 1) {
            setGeneralNotificationData(response.data)
        }
    }

    useEffect(() => {
        generalNotificationList();
    }, [])

    return (
        <div className="activity-box" role="tablist">
            <Link to="/funezone" role="presentation" title={t("Games")} state={{ activeTab: "gameTab", key: "game" }} className="game-activity funzone-game" >
                <h4 className={(generalNotificationData?.funzone?.game > 0) ? "notify" : ""}><TextToSpeech text={t('Games')} /></h4>
                {generalNotificationData && (generalNotificationData?.funzone?.game > 0) && <div className="new_notify jsBlink"><p className="badge-text"><TextToSpeech text={t('New')} /></p></div>}
            </Link>
            <Link to="/funezone" role="presentation" title={t("Cartoons")} state={{ activeTab: "cartoonTab", key: "cartoon" }} className="game-activity funzone-cartoon" >
                <h4 className={(generalNotificationData?.funzone?.cartoon > 0) ? "notify" : ""}><TextToSpeech text={t('Cartoons')} /></h4>
                {generalNotificationData && (generalNotificationData?.funzone?.cartoon > 0) && <div className="new_notify jsBlink"><p className="badge-text"><TextToSpeech text={t('New')} /></p></div>}
            </Link>
            <Link to="/funezone" role="presentation" title={t("Wellbeing")} state={{ activeTab: "wellBeingTab", key: "well_being" }} className="game-activity funzone-wellBeing" >
                <h4 className={(generalNotificationData?.funzone?.well_being > 0) ? "notify" : ""}><TextToSpeech text={t('Wellbeing')} /></h4>
                {generalNotificationData && (generalNotificationData?.funzone?.well_being > 0) && <div className="new_notify jsBlink"><p className="badge-text"><TextToSpeech text={t('New')} /></p></div>}
            </Link>
            <Link to="/funezone" role="presentation" title={t("Memes")} state={{ activeTab: "memesTab", key: "meme" }} className="game-activity funzone-memes" >
                <h4 className={(generalNotificationData?.funzone?.meme > 0) ? "notify" : ""}><TextToSpeech text={t('Memes')} /></h4>
                {generalNotificationData && (generalNotificationData?.funzone?.meme > 0) && <div className="new_notify jsBlink"><p className="badge-text"><TextToSpeech text={t('New')} /></p></div>}
            </Link>
            <Link to="/funezone" role="presentation" title={t("Videos")} state={{ activeTab: "videoTab", key: "video" }} className="game-activity funzone-videos" >
                <h4 className={(generalNotificationData?.funzone?.video > 0) ? "notify" : ""}><TextToSpeech text={t('Videos')} /></h4>
                {generalNotificationData && (generalNotificationData?.funzone?.video > 0) && <div className="new_notify jsBlink"><p className="badge-text"><TextToSpeech text={t('New')} /></p></div>}
            </Link>
            <Link to="/funezone" role="presentation" title={t("Musics")} state={{ activeTab: "musicTab", key: "music" }} className="game-activity funzone-musics" >
                <h4 className={(generalNotificationData?.funzone?.music > 0) ? "notify" : ""}><TextToSpeech text={t('Musics')} /></h4>
                {generalNotificationData && (generalNotificationData?.funzone?.music > 0) && <div className="new_notify jsBlink"><p className="badge-text"><TextToSpeech text={t('New')} /></p></div>}
            </Link>
        </div>
    );
};

export default Funzone;