import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import { get_faqs_list } from '../../../services/user_apis'
import TextToSpeech from '../../../services/textToSpeech'

const Faq = ({ socketData }) => {
  const { t } = useTranslation();
  const [faqData, setFaqData] = React.useState([]);
  const language = useSelector((state) => state.language.value);


  React.useEffect(() => {
    const contentDetails = async () => {
      const response = await get_faqs_list({  page: 1, limit: 100, sort_by: 'id', order_by: 'desc' }, language)
      // console.log("get faq details ==> ", response.data.list)
      if (response.code === 1) {
        setFaqData(response.data.list)
      }
    }
    contentDetails()
  }, [])
  return (
    <React.Fragment>

      <div className="content">
        <div className="left-portion">
          <Left_Panel />
        </div>
        <div className="middle-portion">
          <Home_Header socketData={socketData} />
          <div className="container-fluid">

            <section className="page-banner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="banner-title"><TextToSpeech text={t('FAQ')} /></h2>
                    {/* <div className="bread-crumbs">
                      <a href="../" title={t("Home")}><TextToSpeech text={t('Home')} /></a> <span></span> <TextToSpeech text={t('Faq')} />
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
            <section className="pt-4">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="faq-search">
                      <div className="form-group">
                        <label for={t("Search")} className="hidden">{t("Search")}</label>
                        <input type="text" name="" role="searchbox" placeholder={t('Search here...')} className="form-control" />
                        <div className="search-position">
                          <button className="search-icon" aria-label={t("Search Box")}></button>
                        </div>
                      </div>
                    </div>

                    <div id="accordionExample" className="accordion faq-accoridan" role="tablist">

                      {faqData &&
                        faqData.map((item, index) => (
                          <div className="card text-left" key={index} role="presentation">
                            <div id="headingOne" className="card-header bg-white border-0">
                              <h2 className="mb-0">
                                <label for={t("Close")} className="hidden">{t("Close")}</label>
                                <button type="button" data-toggle="collapse" data-target={`#collapse${index}`} aria-expanded="false"
                                  aria-controls={`collapse${index}`}
                                  className="btn  text-dark font-weight-bold text-uppercase collapsible-link"><TextToSpeech text={`${index + 1}  ${t((language === 'en' || !language) ? item?.en_question : item?.tu_question)}`} /></button>
                              </h2>
                            </div>
                            <div id={`collapse${index}`} aria-labelledby="headingOne" data-parent="#accordionExample" className="collapse ">
                              <div className="card-body ">
                                <p className="font-weight-light m-0"><TextToSpeech text={t((language === 'en' || !language) ? item?.en_answer : item?.tu_answer)} /></p>
                              </div>
                            </div>
                          </div>
                        ))
                      }

                    </div>
                  </div>

                </div>
              </div>
            </section>
          </div>
        </div>


      </div>
      <Modal />
    </React.Fragment>
  )
}

export default Faq