import React, { useState } from 'react'
import env from 'react-dotenv'
import { useSelector } from 'react-redux';
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import '../my-homework-teacher/AddHomeWork.css'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { uploadImage } from "../../../services/imageUpload";
import { add_lecture, update_lecture } from '../../../services/user_apis'
import { useTranslation } from 'react-i18next'
import DatePicker, { registerLocale, setDefaultLocale } from  "react-datepicker";
import { tr } from 'date-fns/locale/tr';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import TextToSpeech from '../../../services/textToSpeech'

export default function AddHomeWork({ socketData }) {
   const [lastSubmissionDate, setLastSubmissionDate] = useState(null);
   const [startTime, setStartTime] = useState(null);
   const [endTime, setEndTime] = useState(null);
   let location = useLocation()
   registerLocale('tr', tr);
   const language = useSelector((state) => state.language.value);
   const { t } = useTranslation();
   let { id } = useParams()
   var idList = id.split("&");

   let navigate = useNavigate();

   const type = idList[0]
   const classId = idList[1]
   const divisionId = idList[2]

   let user = JSON.parse(localStorage.getItem('user'));

   React.useEffect(() => {
      // console.log("locatoin data  ==> ", location.state)
      if (location.state != null) {

         let SECONDS = convertDurationtoSeconds(location.state.classData.lecture_time) + convertDurationtoSeconds(location.state.classData.lecture_duration)
         let endTime = new Date(SECONDS * 1000).toISOString().substring(11, 16)
         document.getElementById("Title").value = location.state.classData.lecture_subject_name
         document.getElementById("ClassDate").value = lastSubmissionDate?  lastSubmissionDate : location.state.classData.lecture_date
         document.getElementById("Instruction").value = location.state.classData.lecture_instruction
         document.getElementById("Starttime").value = location.state.classData.lecture_time
         document.getElementById("Endtime").value = endTime
         document.getElementById("classLink").value = location.state.classData.lecture_link
      }
   }, [])
   function convertDurationtoSeconds(duration) {
      return moment(duration, "h:mm A").unix();
   };

   const clickSubmitButton = async (e) => {
      e.preventDefault();

      let SECONDS = convertDurationtoSeconds(document.getElementById("Endtime").value) - convertDurationtoSeconds(document.getElementById("Starttime").value)
      let duration = new Date(SECONDS * 1000).toISOString().substring(11, 16)
      // console.log("sec", duration)
      const post_body = {
         lecture_duration: duration,
         lecture_subject_name: document.getElementById("Title").value,
         class_id: classId,
         division_id: divisionId,
         lecture_teacher_name: user?.display_user_name != "" ? user?.display_user_name : user.username,
         lecture_date: lastSubmissionDate,
         lecture_time: document.getElementById("Starttime").value,
         lecture_datetime: new Date(),
         lecture_link: document.getElementById("classLink").value,
         lecture_instruction: document.getElementById("Instruction").value,
      }

      if (location?.state?.isEdit) {
         post_body["lecture_id"] = location.state.classData.lecture_id
         const response = await update_lecture(post_body)
         // console.log("create online class   ===> ", response)
         if (response.code === 1) {
            navigate(-1)
         }
      } else {
         const response = await add_lecture(post_body)
         // console.log("create online class   ===> ", response)
         if (response.code === 1) {
            navigate(-1)
         }
      }
      // return
   }

   return (
      <React.Fragment>
         <div className="content">
            <div className="left-portion">
               <Left_Panel />
            </div>
            <div className="middle-portion">
               <Home_Header socketData={socketData} />
               <div className="container-fluid">
                  <section className="post-section pt-4">
                     <div className="container">
                        <div className="row justify-content-center">
                           <div className="col-lg-10">
                              <div className="my-school">
                                 <div className="create-post">
                                    <div className="post-title text-center">
                                    <TextToSpeech text={location.state != null && location.state.isEdit === true ? <h3>{('Edit Online Class')}</h3> : <h3>{t('Create Online Class')}</h3>} />
                                    </div>
                                    <form id="form_validation" method="" onSubmit={clickSubmitButton} data-message={t("Add online class")} action="#">
                                       <div className="class-form">
                                          <div className="form-group ">
                                             <p className='text-left ml-3 mb-1'><TextToSpeech text={t('Title of the Class *')} /></p>
                                             <input type="text" aria-label={t("Title of the Class")} name="TItle" id="Title" placeholder={t('Title of the Class *')} className="form-control" required />
                                          </div>
                                          <div className="form-group">
                                             <p className='text-left ml-3 mb-1'><TextToSpeech text={t('Instruction')} /></p>
                                             <textarea aria-label={t("Instruction")} className="form-control" name='Instruction' id='Instruction' placeholder={t('Instruction')} rows="5" required></textarea>
                                          </div>
                                          <div className='row'>
                                             <div className="form-group col-4">
                                                <p className='text-left ml-3 mb-1'><TextToSpeech text={t('Date of Class *')} /></p>
                                                <DatePicker icon="fa fa-calendar" aria-label={t("Date of Class")} type="date" name="ClassDate" id="ClassDate" placeholderText={t('Last Submission Date')} selected={lastSubmissionDate}  locale={language === 'tu' ? 'tr' : language} onChange={(date) => { setLastSubmissionDate(date);} } className="form-control datepicker" required />
                                             </div>
                                             <div className="form-group col-4">
                                                <p className='text-left ml-3 mb-1'><TextToSpeech text={t('Start Time *')} /></p>
                                                <DatePicker icon="fa fa-calendar" aria-label={t("Start Time")} name="Starttime" id="Starttime" placeholderText={t('Start Time')} selected={startTime}  locale={language === 'tu' ? 'tr' : language} onChange={(date) => { setStartTime(date);} } className="form-control datepicker" showTimeSelect showTimeSelectOnly timeIntervals={5} timeCaption={t("Time")} dateFormat="h:mm aa" required />
                                             </div>
                                             <div className="form-group col-4">
                                                <p className='text-left ml-3 mb-1'><TextToSpeech text={t('End Time *')} /></p>
                                                <DatePicker icon="fa fa-calendar" aria-label={t("End Time")} name="Endtime" id="Endtime" placeholderText={t('End Time')} selected={endTime}  locale={language === 'tu' ? 'tr' : language} onChange={(date) => { setEndTime(date);} } className="form-control datepicker" showTimeSelect showTimeSelectOnly timeIntervals={5} timeCaption={t("Time")} dateFormat="h:mm aa" required />
                                             </div>
                                          </div>
                                          <div className="form-group ">
                                             <p className='text-left ml-3 mb-1'> <TextToSpeech text={t('Meeting Link *')} /></p>
                                             <input aria-label={t("Enter Class Link")} type="text" name="classLink" id="classLink" placeholder={t('Enter Class Link')} className="form-control" required />
                                          </div>
                                          <div className="form-group text-center">
                                             <button aria-label={t("Add/Edit Online Class")} className="cybersmart-btn-m btn-block" data-dismiss="modal" type='submit' ><TextToSpeech text={location.state != null && location.state.isEdit === true ? t('Update') : t('Create Class')} /></button>
                                          </div>
                                       </div>
                                    </form>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
               </div>

            </div>
            <div className="content">
               <div className="middle-portion">
               </div>
            </div>

         </div>
         <Modal />
      </React.Fragment>
   )
}
