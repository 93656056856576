import React,{useState} from 'react'
import env from 'react-dotenv'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import '../my-homework-teacher/HomeWorkDetails.css'
import { useLocation } from 'react-router-dom';
import { get_homework_details,delete_home_work,update_home_work} from '../../../services/user_apis'
import moment from 'moment'
import Swal from 'sweetalert2'
import {  useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import TextToSpeech from '../../../services/textToSpeech'

export default function HomeWorkDetailsTeacher({ socketData }) {
   const { t } = useTranslation();
   const { state } = useLocation();
   const [homeWorkDetails, setHomeworkDetails] = React.useState();
   let navigate = useNavigate();

   React.useEffect(() => {
      // console.log("Call Home work details api")
      // console.log("Home Data",state)
      const homeWorkDetails = async () => {
          const post_body = {
              "homework_id": state.homworkData.homework_id
          }
          const response = await get_homework_details(post_body)
          if (response.code === 1) {
            //   console.log("get homework details data  ===> ", response)
              setHomeworkDetails(response.data)
          }
      }
      homeWorkDetails()
  }, [])

  const deleteHomeWork = ()=>{
   Swal.fire({
      title: t('Are you sure you want to delete?'),
      text: "",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('Yes, delete it!')
    }).then((result) => {
      if (result.isConfirmed) {
         deletHomeWorkApi()
      }
    })
  }

  const deletHomeWorkApi = async () => {
   try {
      var body={"homework_id":state.homworkData.homework_id}
       const response = await delete_home_work(body)
       if (response.code === 1) {
         navigate(-1)
      }
   }
   catch (e) {
       navigate('/')
   }
}

const updateHomeWorkApi = async (date) => {
   try {
      var body={"homework_id":state.homworkData.homework_id,"homework_last_submission_date":date}
       const response = await update_home_work(body)
       if (response.code === 1) {
         navigate(0)
      }
   }
   catch (e) {
       navigate('/')
   }
}
   const changeHomeWorkSchdule = ()=>{
      var selectDataInput="";
       Swal.fire({
         title: t('Please Seletc Last Submission Date'),
         html: '<input type="date" name="hwSubmissionDate" id="hwSubmissionDate" placeholder="Last Submission Date" className="form-control datepicker" required/>',
         stopKeydownPropagation: false,
         preConfirm: () => {
           var selectDate= Swal.getPopup().querySelector('#hwSubmissionDate');
            if (selectDate.value === "") {
               Swal.showValidationMessage(t(`Please select Last Submission Date`))
             }
             selectDataInput=selectDate.value;
         },
       }).then((result) => {
         if (result.isConfirmed) {
            // console.log("Succcess",selectDataInput);
            updateHomeWorkApi(selectDataInput);
//            Swal.fire('Saved!', '', 'success')
         }
       })
   }

   const clickHomeWorkEdit = () => {
      navigate("/edit-home-work/e&" + homeWorkDetails.class_id + "&" + homeWorkDetails.division_id+ "&" + homeWorkDetails.homework_id)
  }


  return (
    <React.Fragment>
    <div className="content">
        <div className="left-portion">
            <Left_Panel />
        </div>
        <div className="middle-portion">
            <Home_Header socketData={socketData} />
            <div className="content">
        <div className="container">
            <div className="row justify-content-center">
               <div className="col-lg-12">
                  <div className="my-school" data-message={t("Edit homework")}>
                     <div className="comment-right">
                        <div className="more">
                           <div className="more-option text-start">
                              <div className="">
                                 <a href="" title={t("Edit Homework")} onClick={clickHomeWorkEdit}><TextToSpeech text={t("Edit Homework")} /></a>
                              </div>
                              <div className="">
                                 <a href="" title={t("Reschedule Submit Date")} data-toggle="modal" data-target="#Reschedule_Modal" onClick={changeHomeWorkSchdule}><TextToSpeech text={t("Reschedule Submit Date")} /></a>
                              </div>
                              <div className="">
                                 <a href="" title={t("Delete Homework")} data-toggle="modal" data-target="#deletehomework_Modal" onClick={deleteHomeWork}><TextToSpeech text={t("Delete Homework")} /></a>
                              </div>
                           </div>
                           <a  className="circle-nav"><i className="fas fa-ellipsis-h"></i></a>
                        </div>
                     </div>
                     <div className="teacher-pro">
                        <img alt={t("Academic image")} src="assets/images/academic.png"/>
                        <h2><TextToSpeech text={homeWorkDetails?.homework_title} /></h2>
                        <label className="posted"><b><TextToSpeech text={moment.utc(homeWorkDetails?.created_at).local().format("DD MMMM, YYYY")} /></b> <i className="fas fa-circle circle-title"></i> <TextToSpeech text={t("Posted by")} /> <b><TextToSpeech text={homeWorkDetails?.teacher_name} /></b></label>
                        <div className="last-date-submission">
                           <p><TextToSpeech text={t("Last Submission Date")} />: <b className="text-dark"><TextToSpeech text={moment(homeWorkDetails?.homework_last_submission_date).format("DD MMMM, YYYY")} /></b></p>
                        </div>
                     </div>
                     <div className="school-section mt-3">
                        <div className="row justify-content-center pt-4">
                           <div className="col-lg-12">
                              <div className="academic-detail text-left">
                              <div className="col-lg-12">
                                 <h4 className="text-color-blue"><TextToSpeech text={homeWorkDetails?.homework_question} /></h4>
                                 <div className='row'>
                                 {homeWorkDetails?.homework_file_list && homeWorkDetails?.homework_file_list.map((item, index) => {
                                    return(
                                    (item.file_type === "image" )? 
                                    <>
                                    {/*} <a target="_blank" href={item.media_file_full_path} > <img src={item.media_file_full_path} width="200" height="200"/></a>
                                    <div className='m-1'>   </div> */}

                                       <a key={index} target="_blank" href={item.media_file_full_path}  title={t("Image")}><div className='m-1'><img alt={t("Image icon")} src={item.file_type === "pdf" ? process.env.PUBLIC_URL + "/assets/images/pdf.png" : (item.file_type === "image" ) ? process.env.PUBLIC_URL + "/assets/images/file.png" : process.env.PUBLIC_URL + "/assets/images/doc.png"} style={{ width: 35, height: 35 }} /> {item.media} </div></a>
                                    </>
                                       :
                                    <>
                                    <a key={index} target="_blank" href={item.media_file_full_path} title={t("File")} ><div className='m-1'><img alt={t("File icon")} src={item.file_type === "pdf" ? process.env.PUBLIC_URL + "/assets/images/pdf.png" : (item.file_type === "image" ) ? process.env.PUBLIC_URL + "/assets/images/file.png" : process.env.PUBLIC_URL + "/assets/images/doc.png"} style={{ width: 35, height: 35 }} /> {item.media} </div></a>
                                    </> 
                                    )                                
                                 })} 
                                 </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>   
        </div>

        </div>

    </div>
    <Modal />
</React.Fragment>
)
}
