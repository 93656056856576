import React,{useState} from 'react'
import env from 'react-dotenv'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import '../my-homework-teacher/SubmitMarks.css'
import Swal from 'sweetalert2'
import { Link, useLocation } from 'react-router-dom';
import {  useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { submission_details_update} from '../../../services/user_apis'
import TextToSpeech from '../../../services/textToSpeech'


export default function RemarksSubmitWork({ socketData }) {
    const { t } = useTranslation();
    const { state } = useLocation();
    let navigate = useNavigate();

    const checkCustom = ()=>{
        if(document.getElementById("customCheckbox9").checked){
            document.getElementById("customReason").disabled = false
        }else{
            document.getElementById("customReason").disabled = true
        }
    }
    setTimeout(()=>{
        document.getElementById("customCheckbox").checked=true
        document.getElementById("customReason").disabled = true
    },100)

    const updateSubmitionDetails = async (body) => {
        try {
           
            const response = await submission_details_update(body)
            // console.log("Update Response",response)
            if (response.code === 1) {
            navigate(-1)
           }
        }
        catch (e) {
            navigate('/')
        }
     }
     
    const buttonClickUpdateStatusRemark = ()=>{

       var marks = document.getElementById("marks").value.trim()
       
       var customeData = document.getElementById("customReason").value.trim()

       if(!document.getElementById("customCheckbox9").checked){
        let selectChecbox = document.getElementsByName('customCheck');
        selectChecbox.forEach((rate) => {
            if (rate.checked) {
                customeData = rate.value;
            }
        });
       }
       
       if(marks===""){
        Swal.fire(
            '',
            t('Please Enter Marks'),
            'error'
          )
       }else if(marks>=101) {
        Swal.fire(
            '',
            t('Please Enter Valid Marks'),
            'error'
          )
       }else if(document.getElementById("customCheckbox9").checked && customeData==="" ){
        Swal.fire(
            '',
            t('Please Enter Custome Remarks'),
            'error'
          )
       }else{
        var body={"submission_id": state.homworkData.submission_id,"submission_status":"result","submission_remark":customeData,"submission_mark":marks}
        // console.log(body)
        updateSubmitionDetails(body)
       }

     }
      return (
    <React.Fragment>
    <div className="content">
        <div className="left-portion">
            <Left_Panel />
        </div>
        <div className="middle-portion">
            <Home_Header socketData={socketData} />
            <div className="container-fluid">
                <section className="post-section pt-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                            <div className="my-school" role="tabpanel">
                                <div className="teacher-pro mark-remark-box">
                                    <img alt={t("Student's profile image")} src={state.homworkData.student_profile }/>
                                    <h2><TextToSpeech text={state.homworkData.student_name} /></h2>
                                    <h3><TextToSpeech text={`${t("Marks")} &amp; ${t("Remarks")}`} /></h3>
                                </div>
                                <div className="row" role="form">
                                    <div className="col-md-5">
                                        <div className="marks-form">
                                        <h4><TextToSpeech text={t("Enter marks below")} /></h4>
                                        <div className="form-group">
                                            <input aria-label={t("Enter Marks")} type="number"  name="marks" id="marks" className="form-control" placeholder={t("Enter Marks")} />
                                            <label><TextToSpeech text={t("100")} /></label>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 text-left">
                                        <div className="remark-list">
                                        <h4><TextToSpeech text={t("Remarks")} /></h4>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox" name="customCheck" className="custom-control-input" aria-label={t("Fantastic and Extraordinary performance! You are the topper. Keep up your learning spirit!")} onChange={checkCustom} value={t("Fantastic and Extraordinary performance! You are the topper. Keep up your learning spirit!")}/>
                                            <label className="custom-control-label" for="customCheckbox"><TextToSpeech text={t("Fantastic and Extraordinary performance! You are the topper. Keep up your learning spirit!")} /></label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox1" name="customCheck" className="custom-control-input" onChange={checkCustom} aria-label={t("Congratulations! You are one among the top performers. Great work!")} value={t("Congratulations! You are one among the top performers. Great work!")}/>
                                            <label className="custom-control-label" for="customCheckbox1"><TextToSpeech text={t("Congratulations! You are one among the top performers. Great work!")} /></label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox2" name="customCheck" className="custom-control-input" onChange={checkCustom} aria-label={t("Great going and good commitment. You are an asset to the class")} value={t("Great going and good commitment. You are an asset to the class")}/>
                                            <label className="custom-control-label" for="customCheckbox2"><TextToSpeech text={t("Great going and good commitment. You are an asset to the class")} /></label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox3" name="customCheck" className="custom-control-input" aria-label={t("Good performance, keep trying, you are close to getting an A")} onChange={checkCustom} value={t("Good performance, keep trying, you are close to getting an A")}/>
                                            <label className="custom-control-label" for="customCheckbox3"><TextToSpeech text={t("Good performance, keep trying, you are close to getting an A")} /></label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox4" name="customCheck" className="custom-control-input" onChange={checkCustom} aria-label={t("Well done but you know and I know you can do better. Keep trying.")} value={t("Well done but you know and I know you can do better. Keep trying.")} />
                                            <label className="custom-control-label" for="customCheckbox4"><TextToSpeech text={t("Well done but you know and I know you can do better. Keep trying.")} /></label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox5" name="customCheck" className="custom-control-input" onChange={checkCustom} aria-label={t("Good going and decent performance. Keep improving your scores")} value={t("Good going and decent performance. Keep improving your scores")}/>
                                            <label className="custom-control-label" for="customCheckbox5"><TextToSpeech text={t("Good going and decent performance. Keep improving your scores")} /></label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox6" name="customCheck" className="custom-control-input"  onChange={checkCustom} aria-label ={t("You passed, well done. With some more effort you will get honours, keep trying.")} value={t("You passed, well done. With some more effort you will get honours, keep trying.")}/>
                                            <label className="custom-control-label" for="customCheckbox6"><TextToSpeech text={t("You passed, well done. With some more effort you will get honours, keep trying.")} /></label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox7" name="customCheck" className="custom-control-input" onChange={checkCustom} aria-label={t("Just a few steps away from the pass mark. Keep trying. You can win next time!")} value={t("Just a few steps away from the pass mark. Keep trying. You can win next time!")}/>
                                            <label className="custom-control-label" for="customCheckbox7"><TextToSpeech text={t("Just a few steps away from the pass mark. Keep trying. You can win next time!")} /></label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="radio" id="customCheckbox9" name="customCheck" className="custom-control-input" aria-label={t("Enter Custom")}  onChange={checkCustom}/>
                                            <label className="custom-control-label" for="customCheckbox9"><TextToSpeech text={t("Custom")} /> </label>
                                        </div>
                                        <div className="form-group mt-3">
                                            <textarea className="form-control form-radius border-0" rows="5" id="customReason" aria-label={t("Enter Remarks")} name="customReason" placeholder={t("Enter Remarks")}></textarea>
                                        </div>
                                        <div className="form-group">
                                            <a title={t("Submit Marks & Remarks")} role="button" onClick={buttonClickUpdateStatusRemark} className="cybersmart-btn btn-block"><TextToSpeech text={`${t("Submit Marks")} &amp; ${t("Remarks")}`} /></a>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
    <Modal />
</React.Fragment>
)

}
