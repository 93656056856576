import moment from 'moment-timezone';
import 'moment/locale/hi';
import 'moment/locale/tr';
// import 'moment/locale/ar';
import GLOBAL_CONSTANT from '../config/global';

const languageSet = localStorage.getItem('language');
if (languageSet) moment.locale(languageSet);

const changeTimeLang = (zoneTime) => {
  if (typeof languageSet !== 'undefined') {
    return moment(zoneTime).locale(languageSet).local().fromNow();
  } else {
    return moment(zoneTime).local().fromNow()
  }
}

export function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}

export function timeConvert(time){
  var zoneTime = moment.tz(time, GLOBAL_CONSTANT.TIME_ZONE);
  return changeTimeLang(zoneTime);
}

export function time12Hour(time24Hour) {
  if (typeof languageSet !== 'undefined') {
    return moment(time24Hour, "HH:mm:ss").locale(languageSet).format("h:mm A");
  } else {
    return moment(time24Hour, "HH:mm:ss").local().format("h:mm A");
  }
}

export function timeDurationDetails(duration) {
  let time = moment(duration, "HH:mm:ss");

  let hours = time.hours();
  let minutes = time.minutes();
  let seconds = time.seconds();

  let formattedTime = "";
  if (hours > 0) formattedTime += moment.duration(hours, "hours").humanize();
  if (minutes > 0) formattedTime += ' ' + moment.duration(minutes, "minutes").humanize();
  if (seconds > 0) formattedTime += ' ' + moment.duration(seconds, "seconds").humanize();

  return formattedTime;
}