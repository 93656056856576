export const contactInputs = [
    {
        id: 1,
        label: "First name",
        type: "text",
        placeholder: "First name",
        name: "first_name",
        errorMessage: 'first name is required',
        value: "",
        required: true
    },
    {
        id: 2,
        label: "Last name",
        type: "text",
        placeholder: "Last name",
        name: "last_name",
        errorMessage: 'last name is required',
        value: "",
        required: true
    },
    {
        id: 3,
        label: "Email",
        type: "email",
        placeholder: "Email",
        name: "email",
        errorMessage: 'Email is required',
        value: "",
        required: true
    },
    {
        id: 4,
        label: "Subject",
        type: "text",
        placeholder: "Subject",
        name: "subject",
        errorMessage: 'Subject is required',
        value: "",
        required: true
    },
    // {
    //     id: 5,
    //     label: "Description",
    //     type: "textarea",
    //     placeholder: "description",
    //     name: "description",
    //     errorMessage: 'Description is required',
    //     value: "",
    //     required: true
    // },

]