import { upload_image } from './user_apis';
import { imageUploadApiCall } from './apiClient';

export const uploadImage = async (file, callbackData) => {
  const newFileName = new Date().getTime() + file.name;
  const content_type = file.type;
  const keyLine = `cybersmarties/homework/${newFileName}`;
  upload_image(keyLine, content_type).then(data => {
    if(data.code === 1) {
      const signedUrl = data.data? data.data : null;
      const fileParameters = {
        signedUrl: signedUrl,
        file: file,
        contentType: content_type,
        onProgress: null
      }
      imageUploadApiCall(fileParameters).then(resultdata => {
        // console.log('uploadImage', resultdata);
        callbackData(newFileName);
      }).catch(err => {
        console.log('uploadImage', err);
      });
    }
  }).catch(err => {
    console.log(err);
  });
}


export const uploadCoverImage = async (file, folderName, callbackData) => {
  const newFileName = new Date().getTime() + file.name;
  const content_type = file.type;
  const keyLine = `cybersmarties/division/${folderName}/${newFileName}`;
  upload_image(keyLine, content_type).then(data => {
    if(data.code === 1) {
      const signedUrl = data.data? data.data : null;
      const fileParameters = {
        signedUrl: signedUrl,
        file: file,
        contentType: content_type,
        onProgress: null
      }
      imageUploadApiCall(fileParameters).then(resultdata => {
        // console.log('uploadImage', resultdata);
        callbackData(newFileName);
      }).catch(err => {
        console.log('uploadImage', err);
      });
    }
  }).catch(err => {
    console.log(err);
  });
}

export const uploadCoverSchoolImage = async (file, folderName, callbackData) => {
  const newFileName = new Date().getTime() + file.name;
  const content_type = file.type;
  const keyLine = `cybersmarties/school/${newFileName}`;
  upload_image(keyLine, content_type).then(data => {
    if(data.code === 1) {
      const signedUrl = data.data? data.data : null;
      const fileParameters = {
        signedUrl: signedUrl,
        file: file,
        contentType: content_type,
        onProgress: null
      }
      imageUploadApiCall(fileParameters).then(resultdata => {
        callbackData(newFileName);
      }).catch(err => {
        console.log('uploadImage', err);
      });
    }
  }).catch(err => {
    console.log(err);
  });
}

export const uploadCoverImageUser = async (file, folderName, callbackData) => {
  const newFileName = new Date().getTime() + file.name;
  const content_type = file.type;
  const keyLine = `cybersmarties/user/${folderName}/${newFileName}`;
  upload_image(keyLine, content_type).then(data => {
    if(data.code === 1) {
      const signedUrl = data.data? data.data : null;
      const fileParameters = {
        signedUrl: signedUrl,
        file: file,
        contentType: content_type,
        onProgress: null
      }
      imageUploadApiCall(fileParameters).then(resultdata => {
        // console.log('uploadCoverImageUser', resultdata);
        callbackData(newFileName);
      }).catch(err => {
        console.log('uploadCoverImageUser', err);
      });
    }
  }).catch(err => {
    console.log(err);
  });
}