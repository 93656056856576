import React, { useEffect } from 'react'
import env from 'react-dotenv'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import bannerprofile from '../my-profile/my-profile-img/banner-profile.png'
import { post_specific_user_api, user_interest_list, my_friend_list, delete_friend, get_user_details, cheer_me_up } from '../../../services/user_apis';
import { CircularProgress } from "@mui/material";
import { Button } from "@mui/material";
import PostListing from "../home/PostListing";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TextToSpeech from '../../../services/textToSpeech'

const Friend_Profile = ({ socketData }) => {

    const { state } = useLocation();
    const { t } = useTranslation();
    const [circular, setcircular] = React.useState(true)
    const [post_list_specific, setPost_list] = React.useState([])
    const [userData, setUserData] = React.useState();
    const [limit, setLimit] = React.useState(10);
    const [sport, setSport] = React.useState([]);
    const [music, setMusic] = React.useState([]);
    let [cheermeup, setCheermeup] = React.useState([]);
    const [reportPostData, setReportPostData] = React.useState("")
    let [reportType, setReportType] = React.useState("");


    let user = JSON.parse(localStorage.getItem('user'))
    useEffect(() => {
        // console.log("Other User Data", state.otherUserData)
        setUserData(state.otherUserData)
        post_user_specific(state.otherUserData.user_id)
        getInterests();
    }, [])

    useEffect(() => {
        post_user_specific(state.otherUserData.user_id);
    },[limit])

    const getInterests = async () => {
        let sports = await user_interest_list({ interest_category: "sport", user_id: state.otherUserData.user_id });
        if (sports.code === 1 && (sports.data).length > 0) {
            sports = (sports.data).filter(item => item.is_selected === 1).reduce((acc,itm) => {
                acc.push(itm.interest_name)
                return acc;
            },[])
            setSport(sports)
        }
        let musics = await user_interest_list({ interest_category: "music", user_id: state.otherUserData.user_id });
        if (musics.code === 1 && (musics.data).length > 0) {
            musics = (musics.data).filter(item => item.is_selected === 1).reduce((acc,itm) => {
                acc.push(itm.interest_name)
                return acc;
            },[])
            setMusic(musics)
        }
    }

    const post_user_specific = async (id) => {
        setcircular(true)
        var body = { "user_id": id, limit: limit, "school_id": user.school_id, }
        const response = await post_specific_user_api(body)
        if (response.code === 1) {
            setPost_list(response.data)
        }
        setcircular(false)
    }

    const cheerMeUp = async () => {
        const response = await cheer_me_up()
        if (response.code === 1) {
            setCheermeup(response.data)
        }
    }
    const updateLimit = () => {
        setcircular(true)
        setLimit(Number(limit) + 10);
    }

    const pagignation = () => {
        if (circular) {
            return <CircularProgress />
        }
        if (!circular) {
            if (post_list_specific.length < limit) {
                return <div className="no-more-activities"><TextToSpeech text={t("No more activities found.")} /></div>
            }
            return <><label for={t("See More")} className="hidden">{t("See More")}</label><Button variant="outlined" size="medium" onClick={updateLimit}><TextToSpeech text={t("See More")} /></Button></>
        }
    }
    
    const loadInterests = () => {
        getInterests()
    }


    return (
        <React.Fragment>
            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid text-left">
                        <div className="row">

                            <div className="col-lg-8 pr-md-0">
                                <div className="main-content" role="tablist">
                                    <div className="right-msg1 mobile-cheermeup">

                                        <div className="msg-title">
                                        <TextToSpeech text={t("Are you Feeling Sad?")} />
                                        </div>
                                        <div className="msg-text">
                                            <TextToSpeech text={t("If you are feeling sad we can cheer up with a small surprise")} />
                                        </div>
                                        <label for={t("Cheer Me Up")} className="hidden">{t("Cheer Me Up")}</label>
                                        <button className="right-btn" data-toggle="modal" data-target="#feel_Modal"><TextToSpeech text={t("Cheer Me Up")} /></button>

                                        <div className="feelings">
                                            <img alt={t("Feeling")} src="assets/web/feelings.png" />
                                        </div>

                                    </div>
                                    <div className='my-profile school-profile'>
                                        <div className="p-banner" style={{ backgroundimage: `url(${bannerprofile})` }}>
                                            <img alt={t("Cover Image")} src="assets/web/cover2.png" />
                                        </div>
                                        <div className="p-icon">
                                            <div className="p-main-pic" >
                                                <img alt={t("Avatar")} src={userData?.avatar_full_path ? userData.avatar_full_path : process.env.PUBLIC_URL + "/assets/web/friend.png"} />
                                            </div>
                                        </div>
                                        <div className="p-name">
                                            <p className="clear-style"><TextToSpeech text={userData?.display_user_name != "" ? userData?.display_user_name : userData.username} /></p>
                                        </div>
                                        <div className="post-content">
                                            <ul role="tablist" className="nav nav-tabs">
                                                <li role="presentation"><a href="#posts" data-toggle="tab" title={t("Posts")} className="active"><TextToSpeech text={t("Posts")} /></a></li>
                                                <li role="presentation"><a href="#aboutmaxy" data-toggle="tab" title={t("About Me")} onClick={loadInterests}> <TextToSpeech text={t("About Me")} /></a></li>
                                            </ul>
                                            <div className="profile-tab-rightside">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-content">
                                    <div className="tab-pane active" id="posts">
                                            {/* {circular && <CircularProgress className="text-center" />} */}
                                            <PostListing data={post_list_specific} 
                                             callBackData={(data) => {
                                                setReportType(data.type)
                                                setReportPostData(data.reportdata)
                                            }} />
                                            <br />
                                            <center>{pagignation()}</center>
                                            <br />
                                            <br />
                                        </div>
                                        <div className="tab-pane" id="aboutmaxy">
                                            <div className="about-profile-info">
                                                <ul role="tablist">
                                                    <li>
                                                        <TextToSpeech className="profile-list-bold" text={`<p>${t('Name')}: <b>${userData?.display_user_name}</b></p>`} />
                                                    </li>
                                                    <li>
                                                        <TextToSpeech className="profile-list-bold" text={`<p>${t('Username')}: <b>${userData?.username}</b></p>`} />
                                                    </li>
                                                    <li>
                                                        <TextToSpeech className="profile-list-bold" text={`<p>${t('About Me')}: <b>${userData?.user_about_me || 'None'}</b></p>`} />
                                                    </li>
                                                    <li>
                                                        <TextToSpeech className="profile-list-bold" text={`<p>${t('Sports')}: <b>${sport.length > 0 ? sport.map(item => t(item)).join(', ') : 'None'}</b></p>`} />
                                                    </li>
                                                    <li>
                                                        <TextToSpeech className="profile-list-bold" text={`<p>${t('Music')}: <b>${music.length > 0 ? music.map(item => t(item)).join(', ') : 'None'}</b></p>`} />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <Modal report_type={reportType} report_data={reportPostData} isUpdate={(data) => (data === true && (setUserData(JSON.parse(localStorage.getItem('user')), post_user_specific()), setReportPostData("")))} />

        </React.Fragment>
    )
}

export default Friend_Profile



