import React, { useState } from 'react'
import env from 'react-dotenv'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import '../my-homework-teacher/HomeWorkDetails.css'
import { Link, useLocation } from 'react-router-dom';
import { submission_details, submission_details_update } from '../../../services/user_apis'
import moment from 'moment'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TextToSpeech from '../../../services/textToSpeech'

export default function SubmitWorkDetails({ socketData }) {
   const { t } = useTranslation();
   const { state } = useLocation();
   const [homeWorkDetails, setHomeworkDetails] = React.useState();
   let navigate = useNavigate();

   React.useEffect(() => {
      // console.log("Call Home work details api")
      // console.log("Home Data", state)

      const submitedHomeWorkDetails = async () => {
         const post_body = {
            "submission_id": state.homworkData.submission_id
         }
         const response = await submission_details(post_body)

         if (response.code === 1) {
            // console.log("get Submited homework details data  ===> ", response)
            setHomeworkDetails(response.data)
         }
      }

      submitedHomeWorkDetails()
   }, [])

   const updateSubmitionDetails = async (body) => {
      try {

         const response = await submission_details_update(body)
         // console.log("Update Response", response)
         if (response.code === 1) {
            navigate(0)
         }
      }
      catch (e) {
         navigate('/')
      }
   }

   const buttonClickUpdateStatusAccepted = () => {
      var body = { "submission_id": state.homworkData.submission_id, "submission_status": "approved" }
      updateSubmitionDetails(body)
   }


   const buttonClickUpdateStatusRejected = () => {

      var selectDataInput = "";
      Swal.fire({
         title: t('Please Enter Rejection Reason'),
         html: '<input type="text" name="hwSubmissionDate" id="hwSubmissionDate" placeholder="'+ t("Rejection Reason") +'" className="form-control" required/>',
         stopKeydownPropagation: false,
         preConfirm: () => {
            var selectDate = Swal.getPopup().querySelector('#hwSubmissionDate');
            if (selectDate.value === "") {
               Swal.showValidationMessage(t(`Please select Last Submission Date`))
            }
            selectDataInput = selectDate.value;
         },
      }).then((result) => {
         if (result.isConfirmed) {
            var body = { "submission_id": state.homworkData.submission_id, "submission_status": "rejected", "submission_remark": selectDataInput }
            updateSubmitionDetails(body)
         }
      })
   }



   return (
      <React.Fragment>
         <div className="content">
            <div className="left-portion">
               <Left_Panel />
            </div>
            <div className="middle-portion">
               <Home_Header socketData={socketData} />
               <div className="container-fluid">
                  <section className="post-section pt-4">
                     <div className="container">
                        <div className="row justify-content-center">
                           <div className="col-lg-12">
                              <div className="my-school" data-message={t("Result details")}>
                                 <div className="teacher-pro">
                                    <img src={homeWorkDetails?.student_profile} />
                                    <h2><TextToSpeech text={homeWorkDetails?.studen_name} /></h2>
                                    <label className="posted"><TextToSpeech text={moment.utc(homeWorkDetails?.created_at).local().format("DD MMMM, YYYY | h:mm a")} /></label>
                                    <div className="academic-body mt-2">
                                       <label className="posted mb-0"><span><TextToSpeech text={state.homworkData.homework_title} /></span></label>
                                       <h3 className="mb-1"><a ><TextToSpeech text={state.homworkData.homework_question} /></a></h3>
                                       <label className="submission-date"><TextToSpeech text={t("Homework Mode")} /> : <TextToSpeech text={(state.homworkData.homework_type === "text") ? t("Text Box") : (state.homworkData.homework_type === "file") ? t("File") : t("Text Box And File")} /></label>
                                    </div>
                                    <div className="last-date-submission">
                                       <div className="score">
                                          <span className={`d-block  text-capitalize ${homeWorkDetails?.status === "rejected" ? 'text-danger' : 'text-success'}`}><TextToSpeech text={t(homeWorkDetails?.status)} /></span>
                                          <span className={`  text-capitalize text-success`}><TextToSpeech text={t(homeWorkDetails?.mark)} /> <span className={` text-capitalize text-secondary`}> / 100</span></span>
                                       </div>

                                    </div>
                                 </div>
                                 <div className="school-section mt-3">
                                    <div className="row justify-content-center pt-4">
                                       <div className="col-lg-12">
                                          <div className="academic-detail text-left">
                                             <p><TextToSpeech text={homeWorkDetails?.submission_answer} /></p>
                                             <div className='row'>
                                                {homeWorkDetails?.submission_file_list && homeWorkDetails?.submission_file_list.map((item, index) => (
                                                   <a title={t("File")} target="_blank" href={item.media_file_full_path} key={index}><div className='m-1'><img alt={("File type")} src={item.file_type === "pdf" ? process.env.PUBLIC_URL + "/assets/images/pdf.png" : (item.file_type === "image") ? (item.media_file_full_path ? item.media_file_full_path : process.env.PUBLIC_URL + "/assets/images/file.png") : process.env.PUBLIC_URL + "/assets/images/doc.png"} style={{ width: 35, height: 35 }} /> </div></a>
                                                ))}
                                             </div>

                                             {homeWorkDetails?.remark != "" &&
                                                <div className="remarks-box bg-light my-4">
                                                   <h5><TextToSpeech text={t("Remarks")} /></h5>
                                                   {<p className="text-success"><TextToSpeech text={t(homeWorkDetails?.remark)} /></p>}
                                                </div>
                                             }
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
               </div>
            </div>

         </div>
         <Modal />
      </React.Fragment>
   )
}
