import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from 'react-redux';
import { addAudio, removeAudio } from '../../store/textspeechSlice';
import { text_speech } from '../user_apis';

const TextToSpeech = ({ visible = false, text, className = '' }) => {
    const speak = localStorage.getItem('speak') === 'true' ? true : false;
    const [size, setSize] = useState({ width: `100%`, height: `${(parseInt(window.innerHeight, 10) - 200)}px` });
    const dispatch = useDispatch();

    useEffect(() => {
        if (window.innerHeight && window.innerWidth) {
            let innerHeight = (parseInt(window.innerHeight, 10) - 160);
            innerHeight = `${(innerHeight < 600 ? innerHeight : 600)}px`;
            const iframe = document.getElementById('musiczone');
            setSize({ width: `100%`, height: innerHeight });
            if (iframe) {
                iframe.style.minHeight = '50%';
                iframe.style.minWidth = '100%';
                iframe.style.maxHeight = '100%';
            } else {
                setSize({ width: `100%`, height: `100%` });
            }
        } else {
            setSize({ width: `100%`, height: `100%` });
        }
        console.log(size);
        
    }, [])

    const handlePlay = () => {
        handleSubmit();
    };

    const handleStop = () => {
        dispatch(removeAudio(null))
    };

    const handleSubmit = async () => {
        try {
            if (speak && text) {
                let result = await text_speech(text.replace(/<[^>]*(>|$)| |‌|»|«|>/g, ' '));
                if (result) {
                    result = JSON.parse(result)
                    if (typeof result.url !== 'undefined') {
                        dispatch(addAudio(result.url))
                    }
                }
            }
        } catch (error) {
            console.error('Error generating speech:', error);
        }
    };

    const htmlOrText = () => {
        // let regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
        let regexForHTML = /<(“[^”]*”|'[^’]*’|[^'”>])*>/;
        let isValid = regexForHTML.test(text);
        if (isValid) {
            return <div className={` ${className}`} style={{ width: size.width, height: size.height }} dangerouslySetInnerHTML={{ __html: text }}></div>
        } else {
            return text;
        }
    }
    
    return (
        <span onMouseLeave={(e)=>{ e.stopPropagation(); handleStop(); }} onMouseEnter={(e)=>{ e.stopPropagation(); handlePlay();}}>{htmlOrText()}</span>
    );

}

export default TextToSpeech;
