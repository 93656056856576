var messages = {
	/*=========================================================Validator messages==============================================*/
	"email": "The :attr must be a valid email address",
	"required": "The :attr field is required",


	/*=========================================================Rest keywords==============================================*/
	//user
	"rest_keyword_user_first_name": "First Name",
	"rest_keyword_user_middle_name": "Middle Name",
	"rest_keyword_user_last_name": "Last Name",
	"rest_keyword_user_country_code": "Country Code",
	"rest_keyword_user_mobile_number": "Mobile Number",
	"rest_keyword_user_email": "Email",
	"rest_keyword_user_marital_status": "Marital Status",
	"rest_keyword_user_password": "Password",

	// Online Users
	"online_user_found": "Online users found",
	"online_user_not_found": "No online user found",

	//forgot password
	"rest_keyword_no_account_exist": "No such account exist with given username",
	"rest_keyword_password_sent_success": "Password Sent to your mail successfully",
	"rest_keyword_password_sent_failed": "Failed to send password to your mail",


	//device
	"rest_keyword_device_type": "Device Type",
	"rest_keyword_device_token": "Device Token",
	"rest_keyword_os_version": "OS Version",
	"rest_keyword_ip": "IP Address",
	"rest_keyword_device_name": "Device Name",
	"rest_keyword_social_id": "Social Id",
	"rest_keyword_login_type": "Login Type",
	"rest_keyword_token": "Token",
	"rest_keyword_old_password": "Old Password",
	"rest_keyword_new_password": "New Password",

	//user 
	"rest_keyword_user_signup_success": "Signup Successfully",
	"rest_keyword_user_signup_failed": "Failed to signup, Please try again",
	"rest_keyword_user_login_success": "Login Successfully",
	"rest_keyword_user_login_failed": "Failed to Login, Incorrect Credentials",
	"rest_keyword_user_logout_success": "Logged Out Successfully",
	"rest_keyword_duplicate_username": "There is already an account registered to this username",
	"rest_keyword_duplicate_email": "There is already an account registered to this email address",
	"rest_keyword_duplicate_mobile_number": "There is already an account registered to this mobile number",
	"rest_keyword_user_update_success": "Profile updated successfully",
	"rest_keyword_user_update_failed": "Failed to update User detail",
	"rest_keyword_password_success": "Password Updated Successfully",
	"rest_keyword_password_failed": "Failed to update Password",
	"rest_keyword_device_info_failed": "Failed to add device info",
	"rest_keyword_survey_data_already_filled": "Your response to this survey has already been submitted",
	"rest_keyword_user_login_count_reached": "Maximum login attempts reached. Please try login in 5 minutes.",

	//post 
	"rest_keyword_add_post_success": "Post Added Successfully",
	"rest_keyword_add_post_failed": "Failed to Add Post,Please try again",
	"rest_keyword_update_post_success": "Post detail Updated Successfully",
	"rest_keyword_update_post_failed": "Failed to update Post detail",
	"rest_keyword_delete_post_success": "Post deleted Successfully",
	"rest_keyword_delete_post_failed": "Failed to delete Post",

	//Question
	"rest_keyword_question_id": "Question Id",
	"rest_keyword_question": "Question",
	"rest_keyword_answer": "Answer",

	//Post
	"rest_keyword_post_id": "Posts Id",
	"rest_keyword_post_category": "Post Category",
	"rest_keyword_post_description": "Post Description",

	//Lacture
	"rest_keyword_update_lecture_detail_success": "Class edited successfully",


	//homework
	"class_id": "Class Id",
	"division_id": "Division Id",
	"homework_title": "Homework Title",
	"homework_type": "Homework Type",
	"homework_question": "Homework Question",
	"homework_file": "Homework File",
	"homework_file_type": "Homework File Type",
	"homework_last_submission_date": "Homework Last Submission Date",
	"homework_id": "Home Work Id",

	//submission
	"submission_type": "Submission Type",

	//common message
	"rest_keyword_invalid_api_key": "Invalid API key",
	"rest_keyword_api_data_found": "Data found",
	"rest_keyword_api_no_data": "No data found",
	"rest_keyword_token_required": "Token is required",
	"rest_keyword_freeze_account": "Sorry your account has freeze, please contact your teacher.",
	"rest_keyword_user_freeze_account": "This student chat is frozen and won't be able to reply.",
	"rest_keyword_token_invalid": "You logged in via a new device.",
	"rest_keyword_something_went_wrong": "Oops! Something went wrong.",
	"rest_keyword_success": "Success",
	"rest_keyword_failed": "Failed",
	"rest_keyword_unique": "Unique Values",
	"rest_keyword_type": "type",
	//like 
	"rest_keyword_like_success": "Liked Successfully",
	"rest_keyword_like_failed": "Failed To Like",
	"rest_keyword_dislike_success": "UnLiked Successfully",
	"rest_keyword_dislike_failed": "Failed To UnLike",

	//comment 
	"rest_keyword_add_comment_success": "Comment Added Successfully",
	"rest_keyword_add_comment_failed": "Failed to Add Comment,Please try again",
	"rest_keyword_update_comment_success": "Comment detail Updated Successfully",
	"rest_keyword_update_comment_failed": "Failed to update Comment detail",
	"rest_keyword_delete_comment_success": "Comment deleted Successfully",
	"rest_keyword_delete_comment_failed": "Failed to delete Comment",

	//report
	"rest_keyword_add_report_success": "Report Added Successfully",
	"rest_keyword_add_report_failed": "Failed to Add Report,Please try again",
	"rest_keyword_update_report_success": "Report detail Updated Successfully",
	"rest_keyword_update_report_failed": "Failed to update Report detail",
	"rest_keyword_delete_report_success": "Report deleted Successfully",
	"rest_keyword_delete_report_failed": "Failed to delete Report",

	//contact us
	"rest_keyword_add_contact_us_success": "Contact Us Successfully",
	"rest_keyword_add_contact_us_failed": "Failed to Contact Us,Please try again",

	// Announcement

	"rest_keyword_announcement_add_success": "Announcement added successfully",
	"rest_keyword_announcement_add_failed": "Failed to add announcement",
	"rest_keyword_announcement_update_success": "Announcement updated successfully",
	"rest_keyword_announcement_update_failed": "Failed to update announcement",
	"rest_keyword_announcement_delete_success": "Announcement deleted successfully",

	// Friend
	"rest_keyword_add_friend_success": "Friend request sent successfully",
	"rest_keyword_failed_friend_failed": "Failed to sent friend request",
	"rest_keyword_delete_friend_success": "Friend request deleted successfully",
	"rest_keyword_accept_request_success": "Friend request accepted successfully",
	"rest_keyword_reject_request_success": "Friend request rejected successfully",

	// Chat
	"rest_keyword_chat_room_exists": "Friend request sent successfully",

	/* user intrest */
	"rest_keyword_update_user_interest_detail_success": "Interest updated successfully.",


	////////////////////////////////////////////////////////////////////////////////////////

	//Admin
	"rest_keyword_admin_login_success": "Login Successfully",
	"rest_keyword_admin_account_inactive": "Your account is inactivated. Please contact to admin",
	"rest_keyword_admin_username_password_invalid": "Please enter valid username/email and password",
	"rest_keyword_add_user_success": "User added successfully",
	"rest_keyword_add_user_failed": "Error while adding user",
	"rest_keyword_user_list_found": "User list found",
	"rest_keyword_user_list_notfound": "User list not found",
	"rest_keyword_user_detail_found": "User detail found",
	"rest_keyword_user_detail_notfound": "User detail not found",
	"rest_keyword_user_detail_update_success": "Profile updated successfully",
	"rest_keyword_setting_detail_update_success": "Setting detail updated successfully",
	"rest_keyword_setting_detail_update_failed": "Error while updating setting detail",
	"rest_keyword_user_detail_update_failed": "Error while updating user detail",
	"rest_keyword_user_detail_deleted_success": "User detail deleted successfully",
	"rest_keyword_user_detail_deleted_failed": "Error while deleting user detail",

	//Admin >> School
	"rest_keyword_add_school_success": "School added successfully",
	"rest_keyword_add_school_failed": "Error while adding school",
	"rest_keyword_school_list_found": "School list found",
	"rest_keyword_school_list_notfound": "School list not found",
	"rest_keyword_school_detail_found": "School detail found",
	"rest_keyword_school_detail_notfound": "School detail not found",
	"rest_keyword_school_detail_update_success": "School detail updated successfully",
	"rest_keyword_school_detail_update_failed": "Error while updating school detail",
	"rest_keyword_school_detail_deleted_success": "School detail deleted successfully",
	"rest_keyword_school_detail_deleted_failed": "Error while deleting school detail",

	//user 
	"rest_keyword_user_signup_success": "Signup Successfully",
	"rest_keyword_user_signup_failed": "Failed to signup, Please try again",
	"rest_keyword_user_login_success": "Login Successfully",
	"rest_keyword_user_login_failed": "Failed to Login, Incorrect Credentials",
	"rest_keyword_user_login_disable": "Your account is disabled by the admin. Please try again later.",
	"rest_keyword_user_logout_success": "Logged Out Successfully",
	"rest_keyword_duplicate_username": "There is already an account registered to this username",
	"rest_keyword_duplicate_email": "There is already an account registered to this email address",
	"rest_keyword_duplicate_mobile_number": "There is already an account registered to this mobile number",
	"rest_keyword_user_update_success": "Profile updated successfully",
	"rest_keyword_user_update_failed": "Failed to update User detail",
	"rest_keyword_password_success": "Password Updated Successfully",
	"rest_keyword_password_failed": "Failed to update Password",
	"rest_keyword_device_info_failed": "Failed to add device info",

	//post 
	"rest_keyword_add_post_success": "Post Added Successfully",
	"rest_keyword_add_post_failed": "Failed to Add Post,Please try again",
	"rest_keyword_update_post_success": "Post detail Updated Successfully",
	"rest_keyword_update_post_failed": "Failed to update Post detail",
	"rest_keyword_delete_post_success": "Post deleted Successfully",
	"rest_keyword_delete_post_failed": "Failed to delete Post",
	"rest_keyword_share_post_success": "You have successfully shared a post",
	"rest_keyword_share_post_failed": "Failed to share a post",

	//homework 
	"rest_keyword_add_homework_success": "Homework Added Successfully",
	"rest_keyword_add_homework_failed": "Failed to Add Homework,Please try again",
	"rest_keyword_update_homework_success": "Homework detail Updated Successfully",
	"rest_keyword_update_homework_failed": "Failed to update Homework detail",
	"rest_keyword_delete_homework_success": "Homework deleted Successfully",
	"rest_keyword_delete_homework_failed": "Failed to delete Homework",

	//submission 
	"rest_keyword_add_submission_success": "Submission Added Successfully",
	"rest_keyword_add_submission_failed": "Failed to Add Submission,Please try again",
	"rest_keyword_update_submission_success": "Submission detail Updated Successfully",
	"rest_keyword_update_submission_failed": "Failed to update Submission detail",
	"rest_keyword_delete_submission_success": "Submission deleted Successfully",
	"rest_keyword_delete_submission_failed": "Failed to delete Submission",

	//class 
	"rest_keyword_add_class_success": "Class Added Successfully",
	"rest_keyword_add_class_failed": "Failed to Add Class,Please try again",
	"rest_keyword_update_class_success": "Class detail Updated Successfully",
	"rest_keyword_update_class_failed": "Failed to update Class detail",
	"rest_keyword_delete_class_success": "Class deleted Successfully",
	"rest_keyword_delete_class_failed": "Failed to delete Class",

	//class 
	"rest_keyword_add_class_success": "Class Added Successfully",
	"rest_keyword_add_class_failed": "Failed to Add Class,Please try again",
	"rest_keyword_update_class_success": "Class detail Updated Successfully",
	"rest_keyword_update_class_failed": "Failed to update Class detail",
	"rest_keyword_delete_class_success": "Class deleted Successfully",
	"rest_keyword_delete_class_failed": "Failed to delete Class",

	//division 
	"rest_keyword_add_division_success": "Class Added Successfully",
	"rest_keyword_add_division_failed": "Failed to Add Class,Please try again",
	"rest_keyword_update_division_success": "Class detail Updated Successfully",
	"rest_keyword_update_division_failed": "Failed to update Class detail",
	"rest_keyword_delete_division_success": "Class deleted Successfully",
	"rest_keyword_delete_division_failed": "Failed to delete Class",

	//fun_zone 
	"rest_keyword_add_fun_zone_success": "Fun Zone Added Successfully",
	"rest_keyword_add_fun_zone_failed": "Failed to Add Fun Zone,Please try again",
	"rest_keyword_update_fun_zone_success": "Fun Zone detail Updated Successfully",
	"rest_keyword_update_fun_zone_failed": "Failed to update Fun Zone detail",
	"rest_keyword_delete_fun_zone_success": "Fun Zone deleted Successfully",
	"rest_keyword_delete_fun_zone_failed": "Failed to delete Fun Zone",

	//interest 
	"rest_keyword_add_interest_success": "Interest Added Successfully",
	"rest_keyword_add_interest_failed": "Failed to Add Interest,Please try again",
	"rest_keyword_update_interest_success": "Interest detail Updated Successfully",
	"rest_keyword_update_interest_failed": "Failed to update Interest detail",
	"rest_keyword_delete_interest_success": "Interest deleted Successfully",
	"rest_keyword_delete_interest_failed": "Failed to delete Interest",

	//lecture 
	"rest_keyword_add_lecture_success": "Lecture Added Successfully",
	"rest_keyword_add_lecture_failed": "Failed to Add Lecture,Please try again",
	"rest_keyword_update_lecture_success": "Lecture detail Updated Successfully",
	"rest_keyword_update_lecture_failed": "Failed to update Lecture detail",
	"rest_keyword_delete_lecture_success": "Lecture deleted Successfully",
	"rest_keyword_delete_lecture_failed": "Failed to delete Lecture",

	//like 
	"rest_keyword_like_success": "Liked Successfully",
	"rest_keyword_like_failed": "Failed To Like",
	"rest_keyword_dislike_success": "UnLiked Successfully",
	"rest_keyword_dislike_failed": "Failed To UnLike",

	//comment 
	"rest_keyword_add_comment_success": "Comment Added Successfully",
	"rest_keyword_add_comment_failed": "Failed to Add Comment,Please try again",
	"rest_keyword_update_comment_success": "Comment detail Updated Successfully",
	"rest_keyword_update_comment_failed": "Failed to update Comment detail",
	"rest_keyword_delete_comment_success": "Comment deleted Successfully",
	"rest_keyword_delete_comment_failed": "Failed to delete Comment",


	//Admin >> Common
	"rest_keyword_dashboard_counts": "Dashboard counts",
	"rest_keyword_page_content_update_success": "Page content updated successfully",
	"rest_keyword_page_content_update_error": "Page content updating error",

	//Admin
	"rest_keyword_admin_login_success": "Login Successfully",
	"rest_keyword_admin_account_inactive": "Your account is inactivated. Please contact to admin",
	"rest_keyword_admin_account_deleted": "Your account is deleted. Please contact to admin",
	"rest_keyword_admin_username_password_invalid": "Please enter valid username/email and password",
	"rest_keyword_admin_change_password_success": "Your password changed successfully",
	"rest_keyword_admin_change_old_password_not_match": "Sorry! Your old password does not match",
	"rest_keyword_admin_change_password_error": "Error while changing your password",
	"rest_keyword_add_admin_success": "Admin added successfully",
	"rest_keyword_add_admin_failed": "Error while adding admin",
	"rest_keyword_admin_list_found": "Admin list found",
	"rest_keyword_admin_list_notfound": "Admin list not found",
	"rest_keyword_admin_detail_found": "Admin detail found",
	"rest_keyword_admin_detail_notfound": "Admin detail not found",
	"rest_keyword_admin_detail_update_success": "Admin detail updated successfully",
	"rest_keyword_admin_detail_update_failed": "Error while updating admin detail",
	"rest_keyword_admin_detail_deleted_success": "Admin detail deleted successfully",
	"rest_keyword_admin_detail_deleted_failed": "Error while deleting admin detail",
	"rest_keyword_add_admin_failed": "Error while adding admin",
	"rest_keyword_admin_list_found": "Admin list found",
	"rest_keyword_admin_list_notfound": "Admin list not found",
	"rest_keyword_admin_detail_found": "Admin detail found",
	"rest_keyword_admin_detail_notfound": "Admin detail not found",
	"rest_keyword_admin_detail_update_success": "Admin detail updated successfully",
	"rest_keyword_admin_detail_update_failed": "Error while updating admin detail",
	"rest_keyword_admin_detail_deleted_success": "Admin detail deleted successfully",
	"rest_keyword_admin_detail_deleted_failed": "Error while deleting admin detail",
	"rest_keyword_assign_school_to_admin_success": "School assigned successfully",
	"rest_keyword_assign_school_to_admin_request_invalid": "Invalid your request",

	//User
	"rest_keyword_add_user_success": "User added successfully",
	"rest_keyword_add_user_failed": "Error while adding user",
	"rest_keyword_user_list_found": "User list found",
	"rest_keyword_user_list_notfound": "User list not found",
	"rest_keyword_user_detail_found": "User detail found",
	"rest_keyword_user_detail_notfound": "User detail not found",
	"rest_keyword_user_detail_update_success": "Profile updated successfully",
	"rest_keyword_user_detail_update_failed": "Error while updating user detail",
	"rest_keyword_user_detail_deleted_success": "User detail deleted successfully",
	"rest_keyword_user_detail_deleted_failed": "Error while deleting user detail",

	//Admin >> School
	"rest_keyword_add_school_success": "School added successfully",
	"rest_keyword_add_school_failed": "Error while adding school",
	"rest_keyword_school_list_found": "School list found",
	"rest_keyword_school_list_notfound": "School list not found",
	"rest_keyword_school_detail_found": "School detail found",
	"rest_keyword_school_detail_notfound": "School detail not found",
	"rest_keyword_school_detail_update_success": "School detail updated successfully",
	"rest_keyword_school_detail_update_failed": "Error while updating school detail",
	"rest_keyword_school_detail_deleted_success": "School detail deleted successfully",
	"rest_keyword_school_detail_deleted_failed": "Error while deleting school detail",

	//Admin >> Class
	"rest_keyword_add_class_success": "Class added successfully",
	"rest_keyword_add_class_failed": "Error while adding class",
	"rest_keyword_class_list_found": "Class list found",
	"rest_keyword_class_list_notfound": "Class list not found",
	"rest_keyword_class_detail_found": "Class detail found",
	"rest_keyword_class_detail_notfound": "Class detail not found",
	"rest_keyword_class_detail_update_success": "Class detail updated successfully",
	"rest_keyword_class_detail_update_failed": "Error while updating class detail",
	"rest_keyword_class_detail_deleted_success": "Class detail deleted successfully",
	"rest_keyword_class_detail_deleted_failed": "Error while deleting class detail",

	//Admin >> Division
	"rest_keyword_add_division_success": "Division added successfully",
	"rest_keyword_add_division_failed": "Error while adding division",
	"rest_keyword_division_list_found": "Division list found",
	"rest_keyword_division_list_notfound": "Division list not found",
	"rest_keyword_division_detail_found": "Division detail found",
	"rest_keyword_division_detail_notfound": "Division detail not found",
	"rest_keyword_division_detail_update_success": "Division detail updated successfully",
	"rest_keyword_division_detail_update_failed": "Error while updating division detail",
	"rest_keyword_division_detail_deleted_success": "Division detail deleted successfully",
	"rest_keyword_division_detail_deleted_failed": "Error while deleting division detail",
	"rest_keyword_division_teacher_list_found": "Division teacher list found",
	"rest_keyword_division_teacher_list_notfound": "Division teacher list not found",

	//Admin >> avatar
	"rest_keyword_add_avatar_success": "Avatar added successfully",
	"rest_keyword_add_avatar_failed": "Error while adding avatar",
	"rest_keyword_avatar_list_found": "Avatar list found",
	"rest_keyword_avatar_list_notfound": "Avatar list not found",
	"rest_keyword_avatar_detail_found": "Avatar detail found",
	"rest_keyword_avatar_detail_notfound": "Avatar detail not found",
	"rest_keyword_avatar_detail_update_success": "Avatar detail updated successfully",
	"rest_keyword_avatar_detail_update_failed": "Error while updating avatar detail",
	"rest_keyword_avatar_detail_deleted_success": "Avatar detail deleted successfully",
	"rest_keyword_avatar_detail_deleted_failed": "Error while deleting avatar detail",

	//Admin >> funzone
	"rest_keyword_add_funzone_success": "Funzone added successfully",
	"rest_keyword_add_funzone_failed": "Error while adding funzone",
	"rest_keyword_funzone_list_found": "Funzone list found",
	"rest_keyword_funzone_list_notfound": "Funzone list not found",
	"rest_keyword_funzone_detail_found": "Funzone detail found",
	"rest_keyword_funzone_detail_notfound": "Funzone detail not found",
	"rest_keyword_funzone_detail_update_success": "Funzone detail updated successfully",
	"rest_keyword_funzone_detail_update_failed": "Error while updating funzone detail",
	"rest_keyword_funzone_detail_deleted_success": "Funzone detail deleted successfully",
	"rest_keyword_funzone_detail_deleted_failed": "Error while deleting funzone detail",

	//Admin >> faqs
	"rest_keyword_add_faqs_success": "Faqs added successfully",
	"rest_keyword_add_faqs_failed": "Error while adding faqs",
	"rest_keyword_faqs_list_found": "Faqs list found",
	"rest_keyword_faqs_list_notfound": "Faqs list not found",
	"rest_keyword_faqs_detail_found": "Faqs detail found",
	"rest_keyword_faqs_detail_notfound": "Faqs detail not found",
	"rest_keyword_faqs_detail_update_success": "Faqs detail updated successfully",
	"rest_keyword_faqs_detail_update_failed": "Error while updating faqs detail",
	"rest_keyword_faqs_detail_deleted_success": "Faqs detail deleted successfully",
	"rest_keyword_faqs_detail_deleted_failed": "Error while deleting faqs detail",

	//Monitoring_staff
	"rest_keyword_monitoring_staff_login_success": "Login Successfully",
	"rest_keyword_monitoring_staff_account_inactive": "Your account is inactivated. Please contact to monitoring staff",
	"rest_keyword_monitoring_staff_account_deleted": "Your account is deleted. Please contact to monitoring staff",
	"rest_keyword_monitoring_staff_username_password_invalid": "Please enter valid username/email and password",
	"rest_keyword_monitoring_staff_change_password_success": "Your password changed successfully",
	"rest_keyword_monitoring_staff_change_old_password_not_match": "Sorry! Your old password does not match",
	"rest_keyword_monitoring_staff_change_password_error": "Error while changing your password",
	"rest_keyword_add_monitoring_staff_success": "Monitoring staff added successfully",
	"rest_keyword_add_monitoring_staff_failed": "Error while adding monitoring staff",
	"rest_keyword_monitoring_staff_list_found": "Monitoring staff list found",
	"rest_keyword_monitoring_staff_list_notfound": "Monitoring staff list not found",
	"rest_keyword_monitoring_staff_detail_found": "Monitoring staff detail found",
	"rest_keyword_monitoring_staff_detail_notfound": "Monitoring staff detail not found",
	"rest_keyword_monitoring_staff_detail_update_success": "Monitoring staff detail updated successfully",
	"rest_keyword_monitoring_staff_detail_update_failed": "Error while updating monitoring staff detail",
	"rest_keyword_monitoring_staff_detail_deleted_success": "Monitoring staff detail deleted successfully",
	"rest_keyword_monitoring_staff_detail_deleted_failed": "Error while deleting monitoring staff detail",
	"rest_keyword_assign_school_to_monitoring_staff_success": "School assigned successfully",
	"rest_keyword_assign_monitoring_staff_to_supervisor_success": "Monitoring Staff assigned successfully",
	"rest_keyword_assign_school_to_monitoring_staff_request_invalid": "Invalid your request",
	"rest_keyword_assign_monitoring_staff_to_supervisor_request_invalid": "Invalid your request",
	"rest_keyword_get_assigned_monitoring_staff_list_found": "Monitoring staff list found",
	"rest_keyword_monitoring_staff_school_list_found": "Monitoring staff school list found",
	"rest_keyword_monitoring_staff_school_list_notfound": "Monitoring staff school list not found",
	"rest_keyword_assigned_monitoring_staff_list_notfound": "Monitoring staff list not found",

	//Admin >> post
	"rest_keyword_post_list_found": "Post list found",
	"rest_keyword_post_list_notfound": "Post list not found",
	"rest_keyword_post_detail_found": "Post detail found",
	"rest_keyword_post_detail_notfound": "Post detail not found",

	//Admin >> comment
	"rest_keyword_comment_list_found": "Comment list found",
	"rest_keyword_comment_list_notfound": "Comment list not found",
	"rest_keyword_comment_detail_found": "Comment detail found",
	"rest_keyword_comment_detail_notfound": "Comment detail not found",

	//Admin >> report
	"rest_keyword_add_report_success": "Report added successfully",
	"rest_keyword_add_report_failed": "Error while adding report",
	"rest_keyword_report_list_found": "Report list found",
	"rest_keyword_report_list_notfound": "Report list not found",
	"rest_keyword_report_detail_found": "Report detail found",
	"rest_keyword_report_detail_notfound": "Report detail not found",
	"rest_keyword_report_detail_update_success": "Report detail updated successfully",
	"rest_keyword_report_detail_update_failed": "Error while updating report detail",
	"rest_keyword_report_detail_deleted_success": "Report detail deleted successfully",
	"rest_keyword_report_detail_deleted_failed": "Error while deleting report detail",

	//Admin >> blocked_word
	"rest_keyword_add_blocked_word_success": "Block word added successfully",
	"rest_keyword_add_blocked_word_failed": "Error while adding block word",
	"rest_keyword_add_blocked_word_duplicate": "This word is already exists",
	"rest_keyword_blocked_word_list_found": "Block word list found",
	"rest_keyword_blocked_word_list_notfound": "Block word list not found",
	"rest_keyword_blocked_word_detail_found": "Block word detail found",
	"rest_keyword_blocked_word_detail_notfound": "Block word detail not found",
	"rest_keyword_blocked_word_detail_update_success": "Block word detail updated successfully",
	"rest_keyword_blocked_word_detail_update_failed": "Error while updating block word detail",
	"rest_keyword_blocked_word_detail_deleted_success": "Block word detail deleted successfully",
	"rest_keyword_blocked_word_detail_deleted_failed": "Error while deleting block word detail",

	//Admin >> monitoring
	"rest_keyword_monitoring_list_found": "Monitoring list found",
	"rest_keyword_monitoring_list_notfound": "Monitoring list not found",
	"rest_keyword_add_capture_data_success": "Data saved successfully",
	"rest_keyword_duplicate_capture_data_found": "Duplicate captured data found",
	"rest_keyword_add_capture_data_failed": "Error while adding data",
	"rest_keyword_capture_data_list_found": "Capture data list found",
	"rest_keyword_capture_data_list_notfound": "Capture data list not found",
	"rest_keyword_capture_data_detail_found": "Block word detail found",
	"rest_keyword_capture_data_detail_notfound": "Block word detail not found",
	"rest_keyword_capture_data_update_success": "Capture_data updated successfully",
	"rest_keyword_capture_data_update_failed": "Error while updating capture_data",

	// Admin >> Survey

	"rest_keyword_survey_data_add_success": "Survey completed successfully",
	"rest_keyword_survey_data_update_success": "Survey data updated successfully",
	"rest_keyword_survey_data_add_failed": "Error while adding Survey data",
	"rest_keyword_survey_data_update_failed": "Error while updating Survey data",
	"rest_keyword_survey_data_list_found": "Survey data list found successfully",
	"rest_keyword_survey_data_list_not_found": "Survey list not found",

	// Admin >> Notification

	"rest_keyword_notification_send_success": "Notification send successfully",
	"rest_keyword_notification_send_failed": "Error while send notification",
	"rest_keyword_notification_list_found": "Notification list found successfully",
	"rest_keyword_notification_list_not_found": "Notification list not found",

	// Admin >> Admin ROle

	"rest_keyword_get_admin_role_success": "Admin role successfully",
	"rest_keyword_get_admin_role_failed": "Error while get admin role",
	"rest_keyword_admin_role_found": "Admin list found successfully",
	"rest_keyword_admin_role_not_found": "Admin role list not found",

	// Admin >> Admin Menu

	"rest_keyword_add_menu_success": "Menu add successfully",
	"rest_keyword_add_menu_failed": "Error while add menu",
	"rest_keyword_menu_found": "Menu list found successfully",
	"rest_keyword_menu_not_found": "Menu role list not found",
	"rest_keyword_add_permission_success": "Permission added successfull",
	"rest_keyword_add_permission_failed": "Error while add permission",

	"End String": "",

	// Upload Image
	"rest_keyword_image_upload_action_missing": " Action missing in image upload",

};
module.exports = messages;