import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import TextToSpeech from '../../../services/textToSpeech';

const MoreLess = (props) => {  

    const { t } = useTranslation();
    const [ brokenText, setBrokenText ] = useState([]);
    const randonID = (Math.random() + 1).toString(36).substring(7);
    const words = props.words? Number(props.words) : 40;

    useEffect(() => {
        const textArray = props.text.split(' ');
        if (textArray.length > words) {
            let first = (textArray.slice(0, words)).join(' ');
            let second = ' ' + textArray.slice(words).join(' ');
            setBrokenText([first, second]);
        } else {
            let first = textArray.join(' ');
            setBrokenText([first]);
        }
    }, []);

    const showMoreLess = async (id) => {
        var elmId = $(`#show-more-content-${id}`).css("display");
        if (elmId === "none") {
            $(`#show-more-content-${id}`).css('display', 'inline');
            $(`#show-more-${id}`).hide();
            $(`#show-less-${id}`).show();
        } else {
            $(`#show-more-content-${id}`).hide();
            $(`#show-less-${id}`).hide();
            $(`#show-more-${id}`).show();
        }
    }

    return (
        <p style={{'padding': '5px'}}>
            {brokenText[0]}
             {brokenText[1]? <><label for={t("Show more")} className="hidden"><TextToSpeech text={t("Show more")} /></label><button id={`show-more-${randonID}`} onClick={() => { showMoreLess(`${randonID}`) }} style={{ 'display': 'inline', 'margin-left': '5px', 'background-color': '#4285f4', 'color': '#fff', 'padding-right': '10px','padding-left': '10px', 'border': '0px', 'border-radius': '10px' }}><TextToSpeech text={t('Show more...')} /></button></> : ''}
            {brokenText[1]? <span id={`show-more-content-${randonID}`} style={{ 'display': 'none' }}>
                {brokenText[1]}
                <br/>
                <label for={t("Show less")} className="hidden">{t("Show less")}</label>
                <button id={`show-less-${randonID}`} onClick={() => { showMoreLess(`${randonID}`) }}  style={{ 'display': 'none', 'background-color': '#fff', 'color': '#4285f4', 'padding-right': '10px','padding-left': '10px', 'margin-top': '10px','margin-buttom': '10px', 'border': '0px', 'border-radius': '10px' }}><TextToSpeech text={t('Show less...')} /></button>
            </span> : ''}
        </p>
    )

}

export default MoreLess;