import React, { useEffect, useState, useRef } from 'react'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import { fun_zone_list } from '../../../services/user_apis'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import TextToSpeech from '../../../services/textToSpeech'

const Fun_Zone = ({ socketData }) => {
    const divRef = useRef(null);
    const [size, setSize] = useState({ width: `100%`, height: `${(parseInt(window.innerHeight, 10) - 200)}px` });
    const { state } = useLocation();
    let url = state.funzonedata.fun_zone_content

    useEffect(() => {
            if (window.innerHeight && window.innerWidth && state.funzonedata.fun_zone_category === 'music') {
                let innerHeight = (parseInt(window.innerHeight, 10) - 160);
                innerHeight = `${(innerHeight < 600 ? innerHeight : 600)}px`;
                setSize({ width: `100%`, height: innerHeight });
                const iframe = document.getElementById('musiczone');
                iframe.style.minHeight = '50%';
                iframe.style.minWidth = '100%';
                iframe.style.maxHeight = '100%';
            } else {
                setSize({ width: `100%`, height: `100%` });
            }
            
    }, [])

    return (
        <React.Fragment>
            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion" ref={divRef}>
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid" role="tabpanel">
                        <div className="row align-item-center justify-content-center my-5" style={{ width: size.width, height: size.height }}><TextToSpeech text={url} /></div>
                    </div>
                </div>
            </div>
            <Modal />
        </React.Fragment>
    )
}


export default Fun_Zone;


