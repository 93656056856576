import { createSlice } from "@reduxjs/toolkit";

const textspeechSlice = createSlice({
    name: 'textspeech',
    initialState: { value: null },
    reducers: {
        addAudio(state, action) {
            state.value = action.payload;
        },
        updateAudio(state, action) {
            state.value = action.payload;
        },
        removeAudio(state, action) {
            state.value = action.payload;
        },
    },
});

export const { addAudio, updateAudio, removeAudio } = textspeechSlice.actions;
export default textspeechSlice.reducer;
