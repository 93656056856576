import { configureStore } from '@reduxjs/toolkit';
import textspeechReducer from './textspeechSlice';
import languageReducer from './languageSlice';
const store = configureStore({
    reducer: {
        textspeech: textspeechReducer,
        language: languageReducer,
    },
});

export default store;
