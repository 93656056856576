import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import GLOBAL_CONSTANT from "../config/global";
import i18next from 'i18next';
import TextProcessor from '../common/text-processor';

const headers = {
  'api-key': 'n4lBiwpD7XqiqSYWQkczLCiGl5QBpPii',
  'Content-Type': 'text/plain',

}

if (GLOBAL_CONSTANT.OFFLINE === 'true') headers['offline-access'] = 'true';

const checkIfBadWords = ( msg ) => {
  if (msg.includes("Your friend might be upset if you use this word")) {
    return TextProcessor.postNotification(msg);
  }
  return msg;
}

const showMessage = (msg) => {
  msg = checkIfBadWords(msg);
  Swal.fire({
    title: i18next.t('Cybersmarties'),
    text: i18next.t(msg),
    confirmButtonText: i18next.t('Okay'),
  })
}

const logoutMessage = (msg, error) => {
  Swal.fire({
    title: i18next.t('Cybersmarties'),
    text: i18next.t(msg),
    cancelButtonText: i18next.t("Cancel"),
    confirmButtonText: i18next.t('Logout'),
    showConfirmButton: true,
    timer: 100000
  }).then((result) => {
    if (result) {
      localStorage.removeItem('user')
      let path = window.location.protocol + "//" + window.location.host + "/"
      window.location.href = path;
      return Promise.reject(error);
    }
  })
}

const axiosClient = axios.create({
  baseURL: `${GLOBAL_CONSTANT.API_URL}/api/v1/`,
  headers: headers
});

const axiosClientChat = axios.create({
  baseURL: `${GLOBAL_CONSTANT.CHAT_API}/`,
  headers: headers
});

const text_speech_headers = {
  'api-key': 'n4lBiwpD7XqiqSYWQkczLCiGl5QBpPii',
  'Content-Type': 'application/json',
}

const axiosTextSpeechClient = axios.create({
  baseURL: `${GLOBAL_CONSTANT.TEXT_SPEECH_API_URL}/`,
  headers: text_speech_headers
});

const upload_headers = {
  'api-key': 'n4lBiwpD7XqiqSYWQkczLCiGl5QBpPii',
  'Content-Type': 'multipart/form-data',
}

if (GLOBAL_CONSTANT.OFFLINE === 'true') upload_headers['offline-access'] = 'true';

// axiosClient.interceptors.request.use(function (config) {
//   config.headers['token'] = "WUVpHuILPofAO9NAmy7QxJFDj1rYdqiRJNv5RFQi8Nk"
//   return config;
// }

// );

axiosClient.interceptors.response.use(
  function (response) {
    if (response.status === 200) {
      if (response.data.code === "0" || response.data.code === 0) {
        if (localStorage.getItem("user") && (response.data.message).includes("9pm to 7am")) {
          localStorage.removeItem('user');
          localStorage.removeItem('isLogin');
          // localStorage.removeItem('language');
          localStorage.removeItem('userId');
          localStorage.removeItem("nCount");
          let path = window.location.protocol + "//" + window.location.host + "/"
          window.location.href = path;
          return Promise.reject('');
        } else {
          showMessage(response.data.message)
        }
      }
      return response;
    }
    else {
      showMessage("Somthing Went Wrong!")
      return response;
    }
  },
  function (error) {
    let res = error.response;

    if (res.status === 401) {
      logoutMessage(res.data.message, error);
    }
  }
);

axiosClientChat.interceptors.response.use(
  function (response) {
    if (response.status === 200) {
      if (response.data.code === "0" || response.data.code === 0) {
        if (localStorage.getItem("user") && (response.data.message).includes("9pm to 7am")) {
          localStorage.removeItem('user');
          localStorage.removeItem('isLogin');
          localStorage.removeItem('language');
          localStorage.removeItem('userId');
          localStorage.removeItem("nCount");
          let path = window.location.protocol + "//" + window.location.host + "/"
          window.location.href = path;
          return Promise.reject('');
        } else {
          showMessage(response.data.message)
        }
      }
      return response;
    }
    else {
      showMessage("Somthing Went Wrong!")
      return response;
    }
  },
  function (error) {
    let res = error.response;

    if (res.status === 401) {
      logoutMessage(res.data.message, error);
    }
  }
);

axiosTextSpeechClient.interceptors.response.use(
  function (response) {
    if (response.status === 200) {
      if (response.data.code === "0" || response.data.code === 0) {
        if (localStorage.getItem("user") && (response.data.message).includes("9pm to 7am")) {
          localStorage.removeItem('user');
          localStorage.removeItem('isLogin');
          localStorage.removeItem('language');
          localStorage.removeItem('userId');
          localStorage.removeItem("nCount");
          let path = window.location.protocol + "//" + window.location.host + "/"
          window.location.href = path;
          return Promise.reject('');
        } else {
          showMessage(response.data.message)
        }
      }
      return response;
    }
    else {
      showMessage("Somthing Went Wrong!")
      return response;
    }
  },
  function (error) {
    let res = error.response;

    if (res.status === 401) {
      logoutMessage(res.data.message, error);
    }
  }
);

const imageUploadApiCall = async (req) => {
  const { signedUrl, file, contentType, onProgress } = req;
  try {
    const resultData = await axios
      .put(signedUrl, file, {
        onUploadProgress: onProgress,
        headers: {
          "Content-Type": contentType,
        },
      });
    return resultData;
  } catch (err) {
    console.log('imageUploadApiCall', err);
  }
}

export {
  axiosClient, axiosClientChat, imageUploadApiCall, axiosTextSpeechClient
};

